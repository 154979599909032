import { createContext, useContext, useReducer } from "react";
import { AuthContext } from "./AuthContext";
import { getUsersChatRoomId } from "../Utils/firebase_helper_functions";

export const ChatContext = createContext();

export const ChatContextProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const INITIAL_STATE = {
    chatId: "null",
    user: {},
  };

  const chatReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          user: action.payload,
          chatId: getUsersChatRoomId(currentUser.uid, action.payload.uid),
        };
      case "DESELECT_USER":
        return {
          user: {},
          chatId: "null",
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  return (
    <ChatContext.Provider value={{ data: state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};
