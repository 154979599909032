

const Groups = () => {
  return (
    <div>
      Groups
    </div>
  )
}

export default Groups
