import './About.css';
import About1 from '../../../assets/image/YUSUFF AWOSANYA.jpg';
import About2 from '../../../assets/image/about-2.webp';

const GauranteeResearch = () => {
  return (
    <div>
    <section id="work-process" className="work-process">
    <div className="container">

        <div className="section-title" data-aos="fade-up">
            <h2>About Dr. Awosanya Yusuff</h2>
            <p className='founders-DP'></p>
        </div>

        <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
                <img src={ About1 } className="img-fluid" alt="" />
            </div>
            <div className="col-md-7 pt-4" data-aos="fade-left">
                <h3>The founder of Daytopia Group</h3>
                <p className="fst-italic">
                Dr. Awosanya Yusuff is a qualified chartered accountant and certified financial advisor who completed his PhD in Finance at 29.  He graduated top of his class in his master's program, MSc in Finance and Management with distinction. He is the Chairman and Co-founder of Vacua Limited, and Daytopia Mentoring Network. He is also the author of the best-selling book: Journey to Becoming a Great student which was recognized by the University of Texas as one of the top 5 books for Black History Month. 
                </p>
                <p>
                While studying for his Ph.D., he was heavily involved in improving international students' lives. He was the student ambassador at the University of Bolton on their Board of Studies for Research Degrees with meetings with the Vice Chancellor and other important decision-makers at the university, he also represented all research students on the Research and Knowledge Exchange Committee where all the thoughts and concerns of research students were passed on to the committee. He later became the vice president of the Postgraduate Students. He received the Bolton Award and the Future Leaders Award before he finally completed his PhD.
                </p>
                <p>
                Dr. Yusuff continued from where he stopped at his university and started the Daytopia Mentoring Platform
                </p>
                <p>
                The platform matches mentees with the right mentors, gives mentees guidance, organizes competitions, creates a networking platform, and shares job opportunities. Still, it grew within 3 years into over 20 universities in Nigeria, Kenya, Ghana, and UK. Daytopia Mentoring Platform now has over 250 mentees. Daytopia also helps their mentees prepare for interviews and they now have mentees in top firms like Bank of America, World Bank, KPMG, venture capital firms, and other top firms.
                </p>
              {/* <ul>
                    <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.
                    </li>
  </ul> */}
            </div>
        </div>


    </div>
</section>
    </div>
  )
}

export default GauranteeResearch
