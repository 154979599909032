import { useState, useEffect } from 'react';
import { db, auth } from '../../firebase'; // Import Firebase instances
import { onAuthStateChanged } from 'firebase/auth'; // Import onAuthStateChanged
import { getDoc, setDoc, doc } from 'firebase/firestore'; // Import Firestore functions
import PromptAlert from './PromptAlert';

const App = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setUser(user);
      if (user) {
        checkPromptCount(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkPromptCount = async (userId) => {
    try {
      const userDocRef = doc(db, 'promptCounts', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists()) {
        const { count } = userDoc.data();
        if (count < 5) {
          setShowAlert(true);
          updatePromptCount(userId, count + 1);
        }
      } else {
        setShowAlert(true);
        setPromptCount(userId);
      }
    } catch (error) {
      console.error('Error checking prompt count:', error);
    }
  };

  const updatePromptCount = async (userId, newCount) => {
    try {
      await setDoc(doc(db, 'promptCounts', userId), { count: newCount });
    } catch (error) {
      console.error('Error updating prompt count:', error);
    }
  };

  const setPromptCount = async (userId) => {
    try {
      await setDoc(doc(db, 'promptCounts', userId), { count: 1 });
    } catch (error) {
      console.error('Error setting prompt count:', error);
    }
  };

  const handleCancel = () => {
    setShowAlert(false);
  };

  const handleSubmit = () => {
    // Handle submit action
    setShowAlert(false);
  };

  return (
    <div className='prompt-real_container'>
      {showAlert && (
        <PromptAlert
          message="Fill our Registation form "
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default App;
