import './team-members.css';
import '../daytopia.css';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from '../../../../Components/Sidebar/sidebar';
import '../../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../../Components/Header/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import Blessing  from '../../../../assets/image/Blessing.jpeg';
import '../../../../Components/Sidebar/sidebar.css'
import Breadcrumbs from './Breadcrumbs';

const MentorBlessing = () => {

   const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 
  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
    <Breadcrumbs />
    <section id="work-process" className="work-process">
    <div className="container">

        <div className="section-title" data-aos="fade-up">
            <h2>About Our Mentor BLESSING OFURE OKORO  </h2>
            <p className='founders-DP'></p>
        </div>

        <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
                <img src={ Blessing  } className="img-fluid" alt="" />
            </div>
            <div className="col-md-7 pt-4" data-aos="fade-left">
               
                <p>
                Blessing Ofure Okoro is a seasoned professional and manager with over 10 years hands-on experience coordinating a variety of teams & projects across various industries. Adept at executing complex programs with exceptional abilities in strategy development, personnel management, communication, monitoring & evaluation, budgeting, project management, business development, financial & programmatic reporting, and stakeholders relationship management. 
                </p>
                <p>
                Over the years, she has work(ed) at various multinational and multicultural organizations including Africa Policy Conversations (AfPC) Initiative, PricewaterhouseCoopers (PwC) Nigeria, Ernst & Young (EY) Nigeria and Friends of Nigeria Europe (FON) France where she demonstrate(d) innovation, teamwork and a continuous desire to gain knowledge and develop skills that improve work performance. 
                </p>

                <p>
                She speaks and writes English and French at native/full professional working proficiency, appropriately deploying these on her projects. 
                </p>

                <p>
                She also loves writing, storytelling and publishing. In 2017, she published her first book titled, 'They Told Us To Dream' and in 2023, she published her second book titled, 'I Will Tell You When I Write About It' (both available on Amazon). 
                </p>

                <p>
                In addition, she is a mentor with Daytopia Mentoring Program - a platform that fosters academic excellence, and she is also a member of Friends of Nigeria Europe - the largest network of Nigerian professionals and entrepreneurs in France.
                </p>

               
               
              {/* <ul>
                    <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.
                    </li>
  </ul> */}
            </div>
        </div>


    </div>
</section>
<Footer />
    </div>
    </div>
  )
}

export default MentorBlessing
