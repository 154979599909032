import { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { auth, storage, db } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Addavatar from '../../assets/icons/icons8-add-administrator-50.png';
import Header from '../../Components/Header/Navbar';
import Footer from '../../Components/Footer/Footer';

const AdminSignup = () => {
  const [err, setErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const firstName = e.target.firstName.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    const file = e.target.file.files[0];

    setIsLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const storageRef = ref(storage, firstName);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        (error) => {
          console.error('Error uploading image:', error);
          setErr(true);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            await updateProfile(user, {
              displayName: firstName,
              photoURL: downloadURL,
            });

            await setDoc(doc(db, 'admins', user.uid), {
              uid: user.uid,
              displayName: firstName,
              email,
              photoURL: downloadURL,
            });

            setIsLoading(false);

            // Navigate to the admin page ("/admin") after sign-up
            navigate('/admin');
          });
        }
      );
    } catch (err) {
      console.error('Firebase authentication error:', err);
      setIsLoading(false);
      setErr(true);
    }
  };

  return (
    <div>
    <Header />
      <section className="h-100 bg-light">
        <div className="container py-5 h-100">
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-md-6">
              <div className="card card-registration">
                <div className="card-body p-md-5 text-dark">
                  <h4 className="mb-5 text-uppercase">Admin Sign Up</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4 signupInput">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="form3Example1m">Full name</label>
                        <input type="text" name="firstName" placeholder='Admin Name' id="form3Example1m" className="form-control form-control-lg" />
                      </div>
                    </div>

                    <div className="mb-3 signupInput">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="admin@example.com"
                      />
                    </div>
                    <div className="mb-3 signupInput">
                      <label htmlFor="password" className="form-label">Password</label>
                      <input
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder="adminpassword"
                      />
                    </div>

                    <div className="mb-3">
                      <input
                        style={{ display: "none" }}
                        id="file"
                        type="file"
                        name="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => {
                          const fileInput = e.target;
                          const imageElement = document.getElementById("avatar-preview");

                          if (fileInput.files && fileInput.files[0]) {
                            const reader = new FileReader();

                            reader.onload = (e) => {
                              imageElement.src = e.target.result;
                            };

                            reader.readAsDataURL(fileInput.files[0]);
                            setErr(false);
                          } else {
                            setErr(true);
                          }
                        }}
                      />
                      <label htmlFor="file" className="signupimagefile">
                        <img src={Addavatar} alt="addavatar" id="avatar-preview" />
                        <span>Add an avatar</span>
                      </label>
                    </div>
                    <button type="submit" className="btn signinbtn btn-lg ms-2">
                      {isLoading ? "Signing Up..." : "Sign Up"}
                    </button>
                    {err && <span>Something went wrong</span>}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AdminSignup;
