import { useContext } from "react";
import Cam from "../../assets/icons/icons8-close-24.png";
import Add from "../../assets/icons/icons8-close-24.png";
import More from "../../assets/icons/icons8-close-24.png";
import Messages from "./Messages";
import Input from "./Input";
import { ChatContext } from "../../context/ChatContext";
import CalendlyWidget from "../../Components/calendly/Calendly";



const Chat = () => {
  const { data, dispatch } = useContext(ChatContext); // Assuming you have a function toggleSidebar to toggle the sidebar visibility

  const isMobileOrTabletView = window.innerWidth < 950;
  let className = "";

  if (isMobileOrTabletView) {
    if (data.user.displayName == undefined) {
      className = "d-none";
    } else {
      className = "";
    }
  }

  const handleBackIconClick = () => {
    dispatch({ type: "DESELECT_USER", payload: {} });
  };
 

  return (
    <div className={`chat ${className}`}>
      <div className="chatInfo">
        <div className="chatInto-init">
          <i
            className="chatIcon_back bi bi-arrow-left"
            onClick={handleBackIconClick}
          ></i>
          <div className="chatIcon_personDetails">
            <img src={data.user?.photoURL} alt="" />
            <h1>{data.user?.displayName}</h1>
          </div>
        </div>
        <div className="chatIcons">
          <img src={Cam} alt="" />
          <img src={Add} alt="" />
          <img src={More} alt="" />
          <CalendlyWidget />
        </div>
      </div>

      <Messages />
      <Input />
    </div>
  );
};
export default Chat;
