import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, query, getDocs, where } from "firebase/firestore";
import { db } from "../../../../firebase";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Navbar from '../../../../Components/Header/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import Breadcrumbs from "./Breadcrumbs";
import Sidebar from '../../../../Components/Sidebar/sidebar';
import Recent from "../../../../Components/sidebarRecent/Recent";
import '../Blog.css';

const BlogDetails = () => {
  const [post, setPost] = useState({});
  const [, setComments] = useState([]);
  const { id } = useParams();
  const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, [auth]);

  const Toggle = () => setToggle(!toggle);

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    };
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "blogPosts"));
      const querySnapshot = await getDocs(q);
      const selectedPost = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .find((item) => item.id === id);
      setPost(selectedPost);
    };

    const fetchComments = async () => {
      const commentsQuery = query(collection(db, "comments"), where("postId", "==", id));
      const commentsSnapshot = await getDocs(commentsQuery);
      const commentsData = commentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setComments(commentsData);
    };

    fetchData();
    fetchComments();
  }, [id]);

  useEffect(() => {
    if (post) {
      document.title = post?.title || "Default Blog Title";
      
      const metaDescription = document.querySelector('meta[name="description"]');
      const ogTitle = document.querySelector('meta[property="og:title"]');
      const ogDescription = document.querySelector('meta[property="og:description"]');
      const ogImage = document.querySelector('meta[property="og:image"]');
      const twitterTitle = document.querySelector('meta[name="twitter:title"]');
      const twitterDescription = document.querySelector('meta[name="twitter:description"]');
      const twitterImage = document.querySelector('meta[name="twitter:image"]');

      if (metaDescription) metaDescription.content = post?.content || "Default Blog Description";
      else {
        const metaTag = document.createElement('meta');
        metaTag.name = 'description';
        metaTag.content = post?.content || "Default Blog Description";
        document.head.appendChild(metaTag);
      }

      if (ogTitle) ogTitle.content = post?.title;
      else {
        const metaTag = document.createElement('meta');
        metaTag.property = 'og:title';
        metaTag.content = post?.title;
        document.head.appendChild(metaTag);
      }

      if (ogDescription) ogDescription.content = post?.content;
      else {
        const metaTag = document.createElement('meta');
        metaTag.property = 'og:description';
        metaTag.content = post?.content;
        document.head.appendChild(metaTag);
      }

      if (ogImage) ogImage.content = post?.imageUrl;
      else {
        const metaTag = document.createElement('meta');
        metaTag.property = 'og:image';
        metaTag.content = post?.imageUrl;
        document.head.appendChild(metaTag);
      }

      if (twitterTitle) twitterTitle.content = post?.title;
      else {
        const metaTag = document.createElement('meta');
        metaTag.name = 'twitter:title';
        metaTag.content = post?.title;
        document.head.appendChild(metaTag);
      }

      if (twitterDescription) twitterDescription.content = post?.content;
      else {
        const metaTag = document.createElement('meta');
        metaTag.name = 'twitter:description';
        metaTag.content = post?.content;
        document.head.appendChild(metaTag);
      }

      if (twitterImage) twitterImage.content = post?.imageUrl;
      else {
        const metaTag = document.createElement('meta');
        metaTag.name = 'twitter:image';
        metaTag.content = post?.imageUrl;
        document.head.appendChild(metaTag);
      }
    }
  }, [post]);

  return (
    <div className="d-flex">
      <div className={toggle ? "d-none" : "w-auto position-fixed"}>
        {userIsLoggedIn && <Sidebar />}
      </div>
      <div className={toggle ? "d-none" : "invisible"}>
        {userIsLoggedIn && <Sidebar />}
      </div>
      <div className="col">
        <Navbar Toggle={Toggle} />
        <Breadcrumbs />
        <section id="blog-details" className="blog-details">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-8">
                <article className="article">
                  <div className="post-img">
                    <img src={post.imageUrl} alt="" className="img-fluid" />
                  </div>
                  <h1 className="blog_title">{post?.title}</h1>
                  <div
                    className="content blog-content"
                    dangerouslySetInnerHTML={{ __html: post?.content }}
                  />
                </article>
              </div>
              <Recent />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default BlogDetails;
