import Ceo from '../../assets/image/YUSUFF AWOSANYA.jpg';
import Quote from '../../assets/image/icons8-quote-24.png';
import './Home.css';


const CEOWord = () => {
  return (
    <div>
    <section id="ceo-word-action" className="ceo-word-action">
    <div className="container" data-aos="fade-up">
      <div className="row justify-content-center">
        <div className="col-lg-6 text-center">
        <img className='ceo-image' src={ Ceo } alt='ceo image' />
         
          <p>
          <img src={ Quote } alt='quote' className='quote-img' />
          <i>
          The only Investment that has no element of risk is the investment in one’s self. Return is always guaranteed.</i>
          </p>

          <h3>Dr. Awosanya Yusuff</h3>

          <p>The Founder of Daytopia Group</p>
        </div>
      </div>

    </div>
  </section>
    </div>
  )
}

export default CEOWord
