import { useState, useEffect, useContext } from "react";
import {
  collection,
  query,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  uploadBytes,
  ref,
  getDownloadURL,
} from "firebase/storage";
import { db, storage } from "../../firebase";
import Sidebar from "../sidebar";
import Navbar from "../Navbar";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; 
import '../admin.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AdminSuccessStories = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // New state for image preview
  const [successStories, setSuccessStories] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [posting, setPosting] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // Check the user's role and navigate to the home page if they are not an admin
    if (!currentUser || currentUser.role !== "admin") {
      navigate('/no-access');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "successStories"));
      const querySnapshot = await getDocs(q);
      const stories = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        stories.push(data);
      });
      setSuccessStories(stories);
    };

    fetchData();
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file);

    // Create a preview for the selected image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleCreateSuccessStory = async () => {
    if (!title || !content || !image) {
      alert("Please fill in all fields.");
      return;
    }

    setPosting(true);

    const storageRef = ref(storage, `images/${image.name}`);
    await uploadBytes(storageRef, image);

    const imageUrl = await getDownloadURL(storageRef);

    const newStoryRef = await addDoc(collection(db, "successStories"), {
      title,
      content,
      imageUrl,
      timestamp: Timestamp.now(),
    });

    setSuccessStories([...successStories, { title, content, imageUrl, id: newStoryRef.id }]);

    setTitle("");
    setContent("");
    setImage(null);
    setImagePreview(null);
    setPosting(false);
  };

  const handleDeleteSuccessStory = async (storyId) => {
    try {
      await deleteDoc(doc(db, "successStories", storyId));
      const updatedStories = successStories.filter((story) => story.id !== storyId);
      setSuccessStories(updatedStories);
    } catch (error) {
      console.error("Error deleting success story:", error);
    }
  };

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    };
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize);
    };

  }, []);

  return (
    <div className="d-flex">
      <div className={toggle ? "d-none" : "w-auto position-fixed"}>
        <Sidebar />
      </div>

      <div className={toggle ? "d-none" : "invisible"}>
        <Sidebar />
      </div>

      <div className='col'>
        <Navbar Toggle={Toggle} />
        <div className="admin">
          <h1> SuccessStories <span className="admintitle">Panel</span></h1>
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <ReactQuill
          theme="snow"
          value={content}
          onChange={(value) => setContent(value)}
          placeholder="Content"
        />
          
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Selected"
              style={{ maxWidth: "100%", marginTop: "10px" }}
            />
          )}
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={handleImageUpload}
          />
          <button onClick={handleCreateSuccessStory}>
            {posting ? "Posting..." : "Post Success Stories"}
          </button>

          <h2>SuccessStories <span className="admintitle">Post</span></h2>
          <ul>
            {successStories.map((story) => (
              <li key={story.id}>
                <p>{story.title}</p>
                <button onClick={() => handleDeleteSuccessStory(story.id)}>Delete</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSuccessStories;
