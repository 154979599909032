import { collection, doc, onSnapshot, } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";
import { db } from "../../firebase";
import { userChatsCollection, chatsCollection } from "../../Utils/collection_names_config";


const truncateText = (text, limit) => {
  if (text) {
    if (text.split(" ").length > limit) {
      const words = text.split(" ").slice(0, limit);
      return words.join(" ") + "...";
    } else {
      return text;
    }
  }
  return "";
};

/*
This is the view for the Chats a user has.
*/
const Chats = () => {
  const [chats, setChats] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);

  useEffect(() => {
    const getChats = () => {

      // the list of  chat rooms that the user has is located in the path
      // userChatsTest/{{userId}}/chatRooms

      const unsub = onSnapshot(doc(db, userChatsCollection, currentUser.uid), (doc) => {
        // Ensure that doc.data() is not null before setting it to state
        if (doc.exists()) {
          setChats(doc.data());
        }
      });

      return () => {
        unsub();
      };
    };

    if (currentUser?.uid) {
      // The user is logged in.
      // Get the chats of the user
      getChats();
    }
  }, [currentUser?.uid]);

  const handleSelect = (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
  };

  const chatsdesign = {
    chatGeneral: {
    
    }
 }

 const currentchatsDeisgn = window.innerWidth < 900 ? chatsdesign : {};
 

  return (
    <div className="chat_general">
    <div style={currentchatsDeisgn.chatGeneral} className="chats " >
      {chats &&
        Object.entries(chats)
          .sort((a, b) => b[1].date - a[1].date)
          .map((chat) => (
            <div
              className="userChat"
              key={chat[0]}
              onClick={() => handleSelect(chat[1].userInfo)}
            >
              <img src={chat[1].userInfo.photoURL} alt="" />
              <div className="userChatInfo">
                <span>{chat[1].userInfo.displayName}</span>
                <p>{truncateText(chat[1].lastMessage?.text, 10)}</p>
              </div>
            </div>
          ))}
    </div>
    </div>
  );
};

export default Chats;