
import About from '../../../assets/image/realAbout.jpeg';
import { Link } from 'react-router-dom';

const Mission = () => {
  return (
    <div>
    <section id="about" className="about">
    <div className="container">
        <div className="row about-flex">

            <div className="col-lg-7 video-box align-self-baseline position-relative">
              <img src={ About } className="img-fluid" alt="daytopia mentors" />
               {/*  <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox play-btn mb-4"></a> */}
            </div>

            <div className="col-lg-5 pt-3 pt-lg-0 content">
                <h3>Our Mission</h3>
                <p className="mission-p">
                Daytopia’s mission is to bridge the skill gap between formal education and the real world by guiding and providing young individuals with critical life and job skills to help them better navigate the over saturated job market.
                </p>
                <Link to='/auth/signup' className='mentor-btn'> JOIN AS A MENTEE </Link>
            </div>

        </div>

    </div>
</section>
    </div>
  )
}

export default Mission
