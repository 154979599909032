import { useState } from 'react';

const MentorSearch = ({ mentors, setFilteredMentors }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    const filteredMentors = mentors.filter((mentor) =>
      mentor.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMentors(filteredMentors);
  };

  const handleKey = (e) => {
    e.code === 'Enter' && handleSearch();
  };

  return (
    <div className='container'>
      <div className="mentor-search">
        <div className="input-search-mentor">
          <i className="bi bi-search" onClick={handleSearch}></i>
          <input
            type="text"
            placeholder="Search mentors..."
            onKeyDown={handleKey}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </div>
        <button onClick={handleSearch} className="search-mentor-btn">
          Search Mentors
        </button>
      </div>
    </div>
  );
};

export default MentorSearch;
