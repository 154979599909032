import RecentBlog from './RecentBlog';
import RecentNews from './RecentNews';
import RecentSuccessStory from './RecentSucessStories';
import './recent.css';

const Recent = () => {
  return (
    <div className="col-lg-4">
    <div className="sidebar-item sidebarRecent-posts">
          <h3 className="sidebar-title">Recent Posts</h3>

          <RecentSuccessStory />

         <RecentNews />

         <RecentBlog />
        </div>
    </div>
    
  )
}

export default Recent