import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './sidebar.css';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const Sidebar = () => {
  const [activeuser, setActiveUser] = useState(1);
  const { currentUser } = useContext(AuthContext);
  const [profileImage, setProfileImage] = useState(currentUser?.photoURL || '');

  useEffect(() => {
    setProfileImage(currentUser?.photoURL || '');
  }, [currentUser]);

  return (
    <div className=' sidebaruser d-flex justify-content-between flex-column  py-3 ps-2 pe-2 text-white  vh-100'>
      <div>
        <a href='' className='text-white p-3 '>
          <i className='bi bi-code-slash text-white'></i>
          <span className='fs-4'>Daytopia Admin Panel </span>
        </a>
        <hr className='sidebartext hrsidebar mt-2' />
        <ul className='nav nav-pills flex-column mt-3'>
          <Link to='/Profile'>
            <li
              className={
                activeuser === 2 ? 'activeuser nav-item pt-2 pb-3 ' : 'nav-item'
              }
              onClick={() => setActiveUser(2)}
            >
              <span className='text-white sidebarflex p-2'>
                <i className='bi bi-person sidebaricon fs-6'></i>
                <span className='sidebartext'>
                  <strong className='fs-6 strongsidebar '>Profile</strong>
                </span>
              </span>
            </li>
          </Link>

          <Link to='/Message'>
            <li
              className={
                activeuser === 2 ? 'activeuser nav-item pt-2 pb-3 ' : 'nav-item'
              }
              onClick={() => setActiveUser(2)}
            >
              <span className='text-white sidebarsideer sidebarflex p-2'>
                <i className='bi bi-chat-dots sidebaricon fs-6'></i>
                <span className='sidebartext'>
                  <strong className='fs-6 strongsidebar'>Message</strong>
                </span>
              </span>
            </li>
          </Link>

          <Link to='/Jobs'>
            <li
              className={
                activeuser === 3 ? 'activeuser nav-item  pt-2 pb-3 ' : 'nav-item '
              }
              onClick={() => setActiveUser(3)}
            >
              <span className='text-white sidebarsideer sidebarflex p-2'>
                <i className='bi bi-person-workspace fs-6 sidebaricon'></i>
                <span className='sidebartext'>
                  <strong className='fs-6  strongsidebar'>Jobs</strong>
                </span>
              </span>
            </li>
          </Link>
        </ul>
      </div>

      <div>
        <hr className='text-white' />
        <div className='nav-item p-2 '>
          <div className='sidebarname'>
            <div className='sidebarProfileimg'>
              <img src={profileImage} alt='user profile image' />
            </div>

            <div>
              <span>
                <strong className='sidebartext strongsidebar sidebarDisplay fs-4'>
                  {currentUser ? (
                    <p>{currentUser.displayName}</p>
                  ) : (
                    <p></p>
                  )}
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
