import UserAuthSignup from "./UserAuthSignup";
import { useState, useEffect } from "react";
import "../admin.css";
import Sidebar from "../sidebar";
import Navbar from "../Navbar";


const UserAuth = () => {
  const [toggle, setToggle] = useState(false);

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    };
    window.addEventListener("resize", handleSize);

    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  return (
    <div className="d-flex">
        <div className={toggle ? "d-none" : "w-auto position-fixed"}>
          <Sidebar />
        </div>

        <div className={toggle ? "d-none" : "invisible"}>
          <Sidebar />
        </div>

        <div className="col">
          <Navbar Toggle={Toggle} />
          <div className="admin">
          <h1>
          Create <span className="admintitle">Mentors Account</span>
        </h1>
    <UserAuthSignup />
    </div>
    </div>
    </div>
  )
}

export default UserAuth