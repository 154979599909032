import { Link } from "react-router-dom"


const Banner = () => {
  return (
    <div>
 
    <section id="call-to-action" className="call-to-action">
      <div className="container" data-aos="fade-up">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center">
            <h3>Join our network of experts and experienced Daytopia mentors</h3>
            <p>
            Do you have any questions for Daytopia Mentoring Network 
            </p>
            <Link className="cta-btn" to="/about/contact">Contact Us</Link>
          </div>
        </div>

      </div>
    </section>
    </div>
  )
}

export default Banner
