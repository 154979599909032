import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import Sidebar from "../../../Components/Sidebar/sidebar";
import Navbar from '../../../Components/Header/Navbar';
import Breadcrumbs from "./Breadcumbs";
import Footer from '../../../Components/Footer/Footer';
import Pagination from '../../../Components/pagination/Pagination';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Skeleton from "react-loading-skeleton";

const News = () => {
  const [newsPosts, setNewsPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    };

    window.addEventListener('resize', handleSize);
    return () => window.removeEventListener('resize', handleSize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(collection(db, "newsPosts"));
        const querySnapshot = await getDocs(q);
        const posts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setNewsPosts(posts);
      } catch (error) {
        console.error("Error fetching news posts:", error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is done
      }
    };

    fetchData();
  }, []);

  const postsPerPage = 8;
  const totalPosts = newsPosts.length;
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = newsPosts.slice(indexOfFirstPost, indexOfLastPost);

  const Toggle = () => {
    setToggle(!toggle);
  };

  return (
    <div className="d-flex">
      <div className={toggle ? "d-none" : "w-auto position-fixed"}>
        {userIsLoggedIn && <Sidebar />}
      </div>

      <div className={toggle ? "d-none" : "invisible"}>
        {userIsLoggedIn && <Sidebar />}
      </div>

      <div className='col'>
        <Navbar Toggle={Toggle}/>
        <Breadcrumbs />
        <section id="blog" className="blog">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <h1 className="blog-heading">News</h1>
            {loading ? ( // Display skeleton loading while data is being fetched
              <div className="row gy-4 posts-list">
                {[...Array(postsPerPage)].map((_, index) => (
                  <div key={index} className="col-xl-4 col-md-6">
                    <Skeleton height={200} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="row gy-4 posts-list">
                {currentPosts.map((post) => (
                  <div key={post.id} className="col-xl-4 col-md-6">
                    <div className="post-item position-relative h-100">
                      <div className="post-img position-relative overflow-hidden">
                        <img src={post.imageUrl} alt="News Image" className="img-fluid" />
                        <span className="post-date">NEWS</span>
                      </div>

                      <div className="blog-logo">
                        <h3>DT</h3>
                      </div>

                      <div className="post-content d-flex flex-column">
                        <Link to={`/journals/news/newsdetails/${post.id}`}>
                          <h3 className="post-title">{post.title}</h3>
                        </Link>
                        <div
                          className="content blog-content"
                          dangerouslySetInnerHTML={{ __html: post.content.slice(0, 300) }}
                        />
                        <hr />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default News;
