import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, query, getDocs, orderBy, limit } from "firebase/firestore";
import { db } from "../../firebase";


const RecentNews = () => {
  
    const [mostRecentNews, setMostRecentNews] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        const q = query(
          collection(db, "newsPosts"),
          orderBy("timestamp", "desc"),
          limit(1) // Limit to the most recent news post
        );
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          // If there is a recent news post, set it as the most recent news
          const doc = querySnapshot.docs[0];
          const data = doc.data();
          setMostRecentNews({ ...data, id: doc.id });
        } else {
          setMostRecentNews(null);
        }
      };
  
      fetchData();
    }, []);
  

  return (
    <div>
    {mostRecentNews ? (
    <div className="post-items">
    <img  src={mostRecentNews.imageUrl} alt=" News details Image" className="flex-shrink-0" />
    <div>
    <Link to={`/journals/news/newsdetails/${mostRecentNews.id}`}>
    <h4>{mostRecentNews.title}</h4>
  </Link>
    </div>
  </div>
  ) : (
    <p>No recent News available.</p>
  )}
  </div>
  )
}

export default RecentNews