import './team-members.css';
import '../daytopia.css';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from '../../../../Components/Sidebar/sidebar';
import '../../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../../Components/Header/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import Lukman from '../../../../assets/image/Lukman.jpeg';
import '../../../../Components/Sidebar/sidebar.css';
import Breadcrumbs from './Breadcrumbs';

const MentorLukman = () => {

    const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 
  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
    <Breadcrumbs />
    <section id="work-process" className="work-process">
    <div className="container">

        <div className="section-title" data-aos="fade-up">
            <h2>About Our Mentor Lukman</h2>
            <p className='founders-DP'></p>
        </div>

        <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
                <img src={ Lukman } className="img-fluid" alt="" />
            </div>
            <div className="col-md-7 pt-4" data-aos="fade-left">
               
                <p>
                Lukman is a Junior Analyst at The World Bank Treasury, where he applies his expertise in sustainable finance, financial risk management, and data analysis to support the institution's global operations and development goals. He has over two years of experience in the finance sector, spanning various roles and domains, such as venture finance, investment banking, pension management, and business development.
                </p>
                <p>
                Lukman has a strong passion for solving African problems and creating a positive social impact. He is the founder of Primer Africa, an online news media that provides tailored solutions and analysis to African issues. He is also a McKinsey Forward Africa participant, a fellow at Millennium Campus Network and Leap Africa, and a volunteer editor at DG Sentinel. Lukman holds a BSc in Economics from IHERIS University. He has also completed several courses and programs on finance, economics, and sustainability from reputable institutions, such as Google, YALI, IMF, World Bank, ADB, UN and Y Combinator.
                </p>
              {/* <ul>
                    <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.
                    </li>
  </ul> */}
            </div>
        </div>


    </div>
</section>
<Footer />
    </div>
    </div>
  )
}

export default MentorLukman
