import NameChange from "./NameChange"
import PasswordChange from "./PasswordChagr"

const Setting = () => {
  return (
    <div className="profiledeatilscontainer">
    <h3 className="title">Settings</h3>
      {/* Your Setting component content */}
      <NameChange />
      <PasswordChange />
    </div>
  )
}

export default Setting