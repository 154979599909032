
import FAQsection from './FAQsection';

const faqData = [
  {
    question: "Can I get mentorship at Daytopia Mentoring Network?",
    answer: "Yes, we have mentors that provide mentorship to young individuals at DMN",
  },
  {
    question: "Is there a charge to use the platform at any stage?",
    answer: "No, DMN is entirely free for mentees and mentors",
  },
  {
    question: "What exactly does the platform offer?",
    answer: "The platform offers one-to one mentoring sessions with one of our expert Daytopia mentors. You can find a mentor as a mentee and message them. Once they reply, you can use their link to book a mentoring session with them. You can also join the groups to catch up with the mentees. ",
  },
  // Add more FAQ items as needed
];

function FaqData() {
  return (
    <div className="App">
      <FAQsection faqData={faqData} />
    </div>
  );
}

export default FaqData;