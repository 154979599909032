import DaytopiaFoundation from '../../../assets/image/DaytopiaFoundation.jpg'

const DaytopiaFoundationDetails = () => {
  return (
    <section id='daytopiaFoundationdetails' className='daytopiaFOundationDetails'>
    <div className='container'>
      <div className='daytopia_foundation_container'>
      <h2>Daytopia Foundation</h2>
      <p>Established to provide sponsorship towards certification and self-development</p>
      <div className='daytopia_foundation_containerdetails'>
      <p>
      Daytopia Foundation is established to provide sponsorship towards certifications and self-development. We very much believe there are a lot of people that really want to invest in their self development but lack the financial capabilities. Hence, the foundation will be sponsoring professional qualifications like ICAN/ACCA fees, nursing exams, and other certifications like Udemy and EdX courses.
      </p>
      <p>
      The process will be made easy. The applicants will simply have to write an application and statement of purpose regarding their need and will be assessed by the Board of Trustees of the foundation.
      </p>
      <p>
      If you are interested as a sponsor of this scheme, kindly contact us by sending an email to <b>daytopiafoundation@daytopia.ng</b>
     
      </p>
      </div>
      <div>
       <img src={DaytopiaFoundation} alt='daytopia foundation image'/>
      </div>
      </div>
    </div>
    </section>
  )
}

export default DaytopiaFoundationDetails