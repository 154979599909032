import './team-members.css';
import '../daytopia.css';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from '../../../../Components/Sidebar/sidebar';
import '../../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../../Components/Header/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import Musa from '../../../../assets/image/Musa.jpeg';
import '../../../../Components/Sidebar/sidebar.css';
import Breadcrumbs from './Breadcrumbs';

const MentorMusa = () => {
    const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 
  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
    <Breadcrumbs />
    <section id="work-process" className="work-process">
    <div className="container">

        <div className="section-title" data-aos="fade-up">
            <h2>About Our Mentor Musa Alkassim</h2>
            <p className='founders-DP'></p>
        </div>

        <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
                <img src={ Musa } className="img-fluid" alt="" />
            </div>
            <div className="col-md-7 pt-4" data-aos="fade-left">
               
                <p>
                Musa Alkassim is a seasoned business analyst, with demonstrated expertise in business strategy, business intelligence, strategic communications, and business development. With a rich professional background, Musa currently serves as a Data Quality Analyst at MagniTT, contributed as a Communication and Community Event Intern at UNDP, and gained valuable experience as a Knowledge Dissemination Intern at the United Nations Office for South-South Cooperation (UNOSSC). 
                </p>
                <p>
                Musa's passion for venture development led to roles such as Venture Fellow at Dream VC and Venture Development Analyst at MZZ Africa. Notably, Musa secured the Tony Elumelu Foundation Entrepreneurial Grant in 2021 showcasing his commitment to entrepreneurship and innovation.
                </p>
              {/* <ul>
                    <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.
                    </li>
  </ul> */}
            </div>
        </div>


    </div>
</section>
<Footer />
    </div>
    </div>
  )
}

export default MentorMusa 
