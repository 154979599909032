import './team-members.css';
import '../daytopia.css';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from '../../../../Components/Sidebar/sidebar';
import '../../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../../Components/Header/Navbar';
import Solomon from '../../../../assets/image/solomon.jpeg';
import Footer from '../../../../Components/Footer/Footer';
import '../../../../Components/Sidebar/sidebar.css'
import Breadcrumbs from './Breadcrumbs';

const MentorSolomon = () => {
    const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 
  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
    <Breadcrumbs />
    <section id="work-process" className="work-process">
    <div className="container">

        <div className="section-title" data-aos="fade-up">
            <h2>About Our Mentor Dr. Bolanle Solomon-Adewusi</h2>
            <p className='founders-DP'></p>
        </div>

        <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
                <img src={ Solomon } className="img-fluid" alt="" />
            </div>
            <div className="col-md-7 pt-4" data-aos="fade-left">
               
                <p>
                Dr. Bolanle Solomon-Adewusi is a writer, speaker, and coach with solid interests in scholarship-related topics, innovation, product management and research. She holds a Ph.D. in Technology Management with a research focus on ICT innovations.
                </p>
                <p>
                She is passionate about connecting individuals who want to pursue graduate studies to sources of funding. She is the Lead Coach at The Scholarship Whizz, where she mentors prospective graduate students on scholarship applications. She has worked with many students and helped them win over $4 million worth of scholarships to study in various countries worldwide. In 2023, she launched her book: Postgraduate Study at Zero Cost: A step by step guide on how to obtain scholarships. You can access the book here: <a href='https://selar.co/tswbook' target='_blank' rel="noreferrer">the book here</a> 
                </p>

                <p>
                She is also actively involved in women and girls’ participation in innovation activities through her non-profit initiative, “The Innovative Woman Africa”, which she founded to educate, inspire and empower African women and girls to enable them to unleash their creativity and critical thinking. She has since attended several international conferences and workshops where she has given presentations on innovation studies.
                </p>
              {/* <ul>
                    <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.
                    </li>
  </ul> */}
            </div>
        </div>


    </div>
</section>
<Footer />
    </div>
    </div>
  )
}

export default MentorSolomon
