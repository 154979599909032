import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db, auth } from "../../firebase";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton"; // Import Skeleton component
import "./Job.css";

const JobListingR = () => {
  const [jobListings, setJobListings] = useState([]);
  const [jobNameFilter, setJobNameFilter] = useState("");
  const [jobTypeFilter, setJobTypeFilter] = useState("");
  const [loading, setLoading] = useState(true); // State to track loading status

  const fetchJobListings = async () => {
    try {
      const jobListingsCollection = collection(db, "jobListings");
      const querySnapshot = await getDocs(jobListingsCollection);
      const jobListingsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Apply filters during fetching
      const filteredListings = jobListingsData
        .filter((job) =>
          job.jobName.toLowerCase().includes(jobNameFilter.toLowerCase())
        )
        .filter((job) =>
          jobTypeFilter === "" || job.jobType === jobTypeFilter
        );

      setJobListings(filteredListings);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error("Error fetching job listings:", error);
    }
  };

  const handleJobNameFilterChange = (e) => {
    setJobNameFilter(e.target.value);
  };

  useEffect(() => {
    // Fetch data only when filters are applied
    fetchJobListings();
  }, [jobNameFilter, jobTypeFilter]);

  return (
    <div className="container">
      <section className="Job">
        <div className="container">
          <div className="filter-section">
            <div className="filterCont">
              <label>Filter Name:</label>
              <input
                type="text"
                value={jobNameFilter}
                onChange={handleJobNameFilterChange}
              />
            </div>
            <div className="filterCont">
              <label>Filter Type:</label>
              <select
                value={jobTypeFilter}
                onChange={(e) => setJobTypeFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="Finance">Finance</option>
                <option value="Software Development">
                  Software Development
                </option>
                <option value="Teaching">Teaching</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Marketing">Marketing</option>
                <option value="Engineering">Engineering</option>
                <option value="Customer Service">Customer Service</option>
                <option value="Design">Design</option>
                <option value="Sales">Sales</option>
                <option value="Human Resources">Human Resources</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Legal">Legal</option>
                <option value="Media and Communication">
                  Media and Communication
                </option>
                <option value="Research">Research</option>
                <option value="Real Estate">Real Estate</option>
                {/* Add other job sectors as needed */}
              </select>
            </div>
          </div>
          <div className="row">
            {/* Display skeleton shimmer while loading */}
            {loading &&
              Array.from({ length: 6 }).map((_, index) => (
                <div
                  key={index}
                  className="col-md-6 col-lg-4 d-flex align-items-stretch"
                  data-aos="fade-up"
                >
                  <div className="icon-box icon-box-pink job-board-container">
                    <div>
                      <Skeleton height={150} />
                      <Skeleton height={20} />
                      <Skeleton height={20} />
                      <Skeleton height={20} />
                      <Skeleton height={20} />
                      <Skeleton height={20} />
                    </div>
                  </div>
                </div>
              ))}
            {/* Render job listings once data is loaded */}
            {!loading &&
              jobListings
                .filter((job) =>
                  job.jobName.toLowerCase().includes(jobNameFilter.toLowerCase())
                )
                .filter((job) =>
                  jobTypeFilter === "" || job.jobType === jobTypeFilter
                )
                .map((job) => (
                  <div
                    key={job.id}
                    className="col-md-6 col-lg-4 d-flex align-items-stretch"
                    data-aos="fade-up"
                  >
                    <div className="icon-box icon-box-pink job-board-container">
                      <div>
                        <div className="job-board-flex-1">
                          <div className="job-board-flex-1-child1">
                            <div className="job-board-flex-inner-1">
                              <img src={job.companyImage} alt="Company Logo" />
                            </div>

                            <div className="job-board-flex-inner-2">
                              <h6>{job.location}</h6>
                            </div>
                          </div>

                          <div className="job-board-flex-1-child2">
                            <h2>DT</h2>
                          </div>
                        </div>

                        <h1 className="job-link">
                          <a href="#">{job.jobName}</a>
                        </h1>
                        <div className="job-detail-time">
                          <div className="job-detail-1">
                            <h6>{job.jobDuration}</h6>
                          </div>

                          <div className="job-detail-2"></div>
                        </div>

                        <p>{job.jobDescription}</p>

                        <div className="job-grid">
                          <p>{job.skills}</p>
                        </div>

                        <div className="job-apply">
                          <div className="job-apply-pay">
                            <h3>
                              <span className="job-apply-span">{job.amount}</span>
                              
                            </h3>
                          </div>

                          <div className="job-button">
                            {auth.currentUser ? (
                              <a
                                href={job.applyUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Apply Now
                              </a>
                            ) : (
                              <Link to="/auth/signin">Sign in to Apply</Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default JobListingR;
