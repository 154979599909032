

const Information = () => {
  return (
    <div>
    <section id="PrivacyPolicy">
    <div className="container">
       
       <p>
       Must be fully registered as a Daytopia member before using the platform. 
        </p>
    </div>
   </section>
    </div>
  )
}

export default Information
