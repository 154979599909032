
import FileDowload from '../../../assets/files/The SIX Pillars of Daytopia Mentoring Network.pdf'

const DownloadFIle = () => {
    const styles = {
        FilesDownload:{
        },
        FIlesCont : {
          padding: '12px',
          border: 'none',
          outline: 'none',
          marginBottom: '52px',
          background: '#2e8ece',
          borderRadius: '50px'
        },
        File:{
            color: '#fff',
           
        }
    }

  return (
    <div className="container">
    <div style={styles.FilesDownload}>
      <button style={styles.FIlesCont}><a style={styles.File} href={FileDowload} download>Six Pillars Of Daytopia Mentoring Network</a></button>
      </div>
    </div>
  )
}

export default DownloadFIle