import './team-members.css';
import '../daytopia.css';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from '../../../../Components/Sidebar/sidebar';
import '../../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../../Components/Header/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import '../../../../Components/Sidebar/sidebar.css';
import DeborahBraid from '../../../../assets/image/Deborah Braide .jpeg';
import Breadcrumbs from './Breadcrumbs';
const TeamMember3 = () => {
    const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 
  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
    <Breadcrumbs />
    <section id="work-process" className="work-process">
    <div className="container">

        <div className="section-title" data-aos="fade-up">
            <h2>About Our Mentor Deborah Braide</h2>
            <p className='founders-DP'></p>
        </div>

        <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
                <img src={ DeborahBraid } className="img-fluid" alt="Deborah Braid" />
            </div>
            <div className="col-md-7 pt-4" data-aos="fade-left">
               
                <p>
                Deborah Braide is an Energy and International development specialist, World Economic Forum Global Shaper, British Chevening alumni and founder of Enercate, a network for STEM and Sustainable energy capacity development
                </p>
                <p>
                . Her work promotes adoption of Sustainable energy towards mitigating climate change and accelerating energy access to unserved and underserved areas, as well as capacity building of women in STEM. She worked on regional sustainable energy projects with public and private stakeholders such as the World Bank, European Union, Nigerian Electricity Regulatory Commission, Rural Electrification Agency(REA), and Office of the Vice President of the Federal Republic of Nigeria.
                </p>
                <p>
                She is committed to Sustainable Development and creating equal opportunities for women and is an Abuja Literary Society Poetry Slam champion.
                </p>
              {/* <ul>
                    <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.
                    </li>
  </ul> */}
            </div>
        </div>


    </div>
</section>
   
   
<Footer /> </div>
    
    </div>
  )
}

export default TeamMember3
