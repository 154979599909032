/* eslint-disable react/jsx-no-target-blank */
import  MyImage  from '../../../assets/image/Daytopia_Workshop.jpeg';
import './daytopiaInvestmenmt.css';

const Word = () => {
  return (
    <section id="daytopiaInvestmentClub" className="daytopiaInvestmentClub section-bg">
    <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Daytopia Investment Club</h2>
          <h3>Join the Daytopia Investment Club</h3>
         
        </div>

      <div className="row">
        <div className="col-lg-6" data-aos="fade-right" data-aos-delay="100">
          <img src={ MyImage } className="img-fluid" alt="My Image" />
        </div>
        <div className="col-lg-6 pt-4 pt-lg-0 daytopiaInvestmentClub d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
          <h3>Mission </h3>
          <p className="fst-italic">
          Daytopia Investment Club is an investment club that educates young daytopians about personal finance and helps them to save and invest. We provide practical financial education and make them more confident about their money. We pool money together to save, invest and loan our members at zero interest rate. The Daytopia Investment Club is only 4 months old and it already has 20 members
          </p>
          <h3>There are few key things you should know about the Investment Club:</h3>
          <ul>
            <li>
            <i className="bi bi-box"></i>
              <div>
                <h5><b>Voting</b></h5>
                <p>Like every other investment club, everyone is a member and as a result has a voting right</p>
              </div>
            </li>
            <li>
              <i className="bi bi-ban"></i>
              <div>
                <h5><b>Stictly for Daytopians</b></h5>
                <p>You must be registered as a Daytopian to join the investment club. Reach out to <a href='mailto:admin@daytopiagroup.com' target='_blank'></a>admin@daytopiagroup.com</p>
              </div>
            </li>
            <li>
            <i className="bi bi-arrow-right-circle-fill"></i>
              <div>
                <h5><b>Rights</b></h5>
                <p>Everyone has equal rights here and every decision made will need to be approved by the majority of the members</p>
              </div>
            </li>
            <li>
            <i className="bi bi-info-circle"></i>
              <div>
                <h5><b>Information</b></h5>
                <p>All members would be kept abreast with the development of the Investment Club via monthly meetings</p>
              </div>
            </li>
            <li>
            <i className="bi bi-transparency"></i>
              <div>
                <h5><b>Transparency</b></h5>
                <p>Everyone will have access to the spreadsheet to understand their stake and how their funds are performing over time.</p>
              </div>
            </li>
            <li>
            <i className="bi bi-book"></i>
              <div>
                <h5><b>Financial Education</b></h5>
                <p>A lot of learning would be involved, which is one of the main goals of DIC</p>
              </div>
            </li>
            <li>
            <i className="bi bi-file-person-fill"></i>
              <div>
                <h5><b>Members Loan and Support</b></h5>
                <p>At the moment we plan to keep 5% of our total funds as cash available to loan out to members. These decisions as said initially will need to be approved by majority</p>
              </div>
            </li>
          </ul>
          <p>
          We only take new daytopians every six months so kindly send an email to daytopiainvestclub@daytopiagroup.com
          </p>
        </div>
      </div>

    </div>
  </section>
  )
}

export default Word