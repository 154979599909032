import { Link } from 'react-router-dom';
import Not404 from '../../assets/image/404.png';
import Opps from '../../assets/image/oops.png';
import './notfound.css'

const NotFoundPage = () => {
  return (
    <section id="404page">
            <div className="container">
                <div className="pageflex">
                    <div className="pageflex-i">
                        <img src={Not404} alt="404" />
                    </div>
                    <div className="pagefle-ii">
                        <img src={Opps} alt="Oops!" />
                        <h3>YOU'RE LOST...</h3>
                        <p>THE PAGE YOU ARE LOOKING FOR MIGHT HAVE BEEN MOVED, RENAMED, OR MIGHT NEVER EXISTED.</p>
                        <Link to="/">Back Home</Link>
                    </div>
                </div>
            </div>
        </section>
  );
};

export default NotFoundPage;
