import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, query, getDocs, orderBy, limit } from "firebase/firestore";
import { db } from "../../firebase";

const RecentBlog = () => {
  const [mostRecentPost, setMostRecentPost] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(db, "blogPosts"),
        orderBy("timestamp", "desc"),
        limit(1) // Limit to the most recent post
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If there is a recent blog post, set it as the most recent post
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        setMostRecentPost({ ...data, id: doc.id });
      } else {
        setMostRecentPost(null);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row gy-4 posts-list">
            {mostRecentPost ? (
              <div key={mostRecentPost.id} className="col-xl-10 col-md-10">
                <div className="post-item position-relative h-100">
                  <div className="post-img position-relative overflow-hidden">
                    <img
                      src={mostRecentPost.imageUrl}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                    <span className="post-date">BLOG</span>
                  </div>

                  <div className="blog-logo">
                    <h3>DG</h3>
                  </div>

                  <div className="post-content d-flex flex-column">
                    <Link to={`/journals/blog/blogdetails/${mostRecentPost.id}`}>
                      <h3 className="post-title">{mostRecentPost.title}</h3>
                    </Link>
                    <div
                className="content blog-content"
                dangerouslySetInnerHTML={{ __html: mostRecentPost.content.slice(0, 200) }}
              />
                   
                  </div>
                </div>
              </div>
            ) : (
              <p>No recent blog available.</p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default RecentBlog;
