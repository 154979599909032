import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const WhatsappIcon = () => {

    const [ userIsLoggedIn, setUserIsLoggedIn] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if(user) {
                setUserIsLoggedIn(true);//User is login in
            }else {
                setUserIsLoggedIn(false); //User is not logged in
            }
        });

        return() => unsubscribe()
    }, [auth]);

  return (
    <div>
        {  userIsLoggedIn &&
        
    (<a
    href="https://chat.whatsapp.com/DofhwOUQYDcB32ksaYzR8l"
    className="whatsapp_float"
    target="_blank"
    rel="noopener noreferrer"
  >
    <i className="bi bi-whatsapp whatsapp-icon"></i>
  </a> )
  }
    </div>
   
  )
}

export default WhatsappIcon