import { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css';

const UserAuthSignup = () => {
  const [userData, setUserData] = useState({
    email: '',
    name: '',
    password: '',
    calendlyLink: ''
  });
  const [creatingMentor, setCreatingMentor] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Check if required fields are present
      if (!userData.email || !userData.name || !userData.password) {
        console.error('Missing required fields');
        return;
      }

      // Update button text to indicate creating mentor
      setCreatingMentor(true);

      // Make a POST request to create user
      const response = await axios.post('http://localhost:3000/api/createUser', userData);
      
      console.log('User created successfully:', response.data);

      // Reset input fields
      setUserData({
        email: '',
        name: '',
        password: '',
        calendlyLink: ''
      });

      // Show success toast notification
      toast.success('Account created successfully');

      // Reset button text after a delay
      setTimeout(() => {
        setCreatingMentor(false);
      }, 3000); // 3000 milliseconds (3 seconds)
    } catch (error) {
      console.error('Error creating user:', error.response.data.error);
      // Handle error here (e.g., display error message to user)

      // Show error toast notification
      toast.error('Error creating account. Please try again.');
      
      // Reset button text
      setCreatingMentor(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type='text' name='name' value={userData.name} onChange={handleInputChange} placeholder='Name' />
        <input type="email" name="email" value={userData.email} onChange={handleInputChange} placeholder="Email" />
        <input type="password" name="password" value={userData.password} onChange={handleInputChange} placeholder="Password" />
        <input type='text' name='calendlyLink' value={userData.calendlyLink} onChange={handleInputChange} placeholder='Calendly Link' />
        <button type="submit" disabled={creatingMentor}>
          {creatingMentor ? 'Creating Mentor...' : 'Create Mentor'}
        </button>
      </form>
      <ToastContainer /> {/* Include ToastContainer here */}
    </div>
  );
};

export default UserAuthSignup;
