import { useEffect, useState, useContext } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import Sidebar from '../sidebar';
import Navbar from '../Navbar';
import { useNavigate } from 'react-router-dom';
import '../admin.css';
import { AuthContext } from '../../context/AuthContext';

const FetchMenteeData = () => {
  const [menteeData, setMenteeData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // Check the user's role and navigate to the home page if they are not an admin
    if (!currentUser || currentUser.role !== 'admin') {
      navigate('/no-access');
    }
  }, [currentUser, navigate]);

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const fetchMenteeData = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const menteeQuery = query(usersCollection, where('role', '==', 'mentee'));
        const menteeSnapshot = await getDocs(menteeQuery);

        const menteeDataArray = [];
        menteeSnapshot.forEach((doc) => {
          const menteeUserData = doc.data();
          menteeDataArray.push({
            id: doc.id, // Add document ID to use it for deletion
            name: menteeUserData.displayName,
            email: menteeUserData.email,
            whatsappNumber: menteeUserData.whatsappNumber,
          });
        });

        setMenteeData(menteeDataArray);
      } catch (error) {
        console.error('Error fetching mentee data:', error);
      }
    };

    fetchMenteeData();
  }, []);

  const handleDeleteMentee = async (id) => {
    try {
      // Delete the mentee document from Firestore
      await deleteDoc(doc(db, 'users', id));

      // Update the state to reflect the changes
      setMenteeData((prevMenteeData) =>
        prevMenteeData.filter((mentee) => mentee.id !== id)
      );
    } catch (error) {
      console.error('Error deleting mentee:', error);
    }
  };

  return (
    <div className="d-flex">
      <div className={toggle ? 'd-none' : 'w-auto position-fixed'}>
        <Sidebar />
      </div>

      <div className={toggle ? 'd-none' : 'invisible'}>
        <Sidebar />
      </div>

      <div className="col">
        <Navbar Toggle={Toggle} />
        <div className="admin">
          <h1>
            Mentee's <span className="admintitle">Panel</span>
          </h1>
          <div className="mentee-container">
            <ol className='mentee-container-List'>
              {menteeData.map((mentee, index) => (
                <li key={index}>
                  <strong>Name:</strong> {mentee.name}, <strong>Email:</strong> {mentee.email},{' '}
                  <strong>WhatsApp Number:</strong> {mentee.whatsappNumber}
                  <button onClick={() => handleDeleteMentee(mentee.id)}>Delete</button>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FetchMenteeData;
