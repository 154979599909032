import { useContext } from "react";
import { ChatContext } from "../../context/ChatContext";
import SearchUser from "./SearchUser";
import Search from "./Search"
import Chats from "./Chats"

const Sidebar = () => {
  const { data  } = useContext(ChatContext);

     const isMobileOrTabletView = window.innerWidth < 950;
     let className = "";

     if(isMobileOrTabletView){
      if(data.user.displayName == undefined){
        className = 'block w-full';
      }else{
        className = 'd-none';
      }
    }


  return (
    <div className={`sidebar ${className}`}>
       <Search />
      {/* <SearchUser /> */}
      <Chats/>
      
    </div>
  );
};

export default Sidebar;
