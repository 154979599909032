import { useContext, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";

import { getUsersChatRoomId } from "../../Utils/firebase_helper_functions";

import { userChatsCollection, chatsCollection } from "../../Utils/collection_names_config";

const Search = () => {
  const [username, setUsername] = useState("");
  const [users, setUsers] = useState([]);
  const [err, setErr] = useState(false);
  const [selectedRole, setSelectedRole] = useState(""); // Default role

  const { currentUser } = useContext(AuthContext);

  const handleSearch = async () => {
    try {
      const usersRef = collection(db, "users");
      const querySnapshot = await getDocs(usersRef);
      const allUsers = querySnapshot.docs.map((doc) => doc.data());
  
      const lowercaseUsername = username.toLowerCase(); // Convert search input to lowercase
  
      let filteredUsers = allUsers.filter((user) => {
        const displayNameLower = user.displayName.toLowerCase();
        return displayNameLower.includes(lowercaseUsername);
      });
  
      if (selectedRole !== "") {
        filteredUsers = filteredUsers.filter((user) => user.role === selectedRole);
      }
  
      setUsers(filteredUsers);
      setErr(false); // Reset error state
    } catch (err) {
      console.error("Error searching users:", err);
      setErr(true);
    }
  };
  
  const handleKey = (e) => {
    if (e.code === "Enter") {
      handleSearch();
    }
  };

  const handleSelect = async (selectedUser) => {
    // Role-specific search as before
    const combinedId = getUsersChatRoomId(currentUser.uid, selectedUser.uid);

    try {
      const res = await getDoc(doc(db, chatsCollection, combinedId));

      if (!res.exists()) {
        // Create a chat in chats collection
        await setDoc(doc(db, chatsCollection, combinedId), { messages: [] });

        // Create user chats
        await updateDoc(doc(db, userChatsCollection, currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: selectedUser.uid,
            displayName: selectedUser.displayName,
            photoURL: selectedUser.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, userChatsCollection, selectedUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }
    } catch (err) {
      console.error("Error creating chat:", err);
    }

    // Reset states
    setUsers([]);
    setUsername("");
  };

  return (
    <div className="search">
      <div className="searchForm">
        <div className="inputSearch_message">
          <i className="bi bi-search"></i>
          <input
            type="text"
            placeholder="Find a user"
            onKeyDown={handleKey}
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
        </div>

        <button onClick={handleSearch} className="search_message">
          Search
        </button>
        <button onClick={handleSearch} className="searchAll_message">
          Search All
        </button>
      </div>
      {err && <span>User not found!</span>}
      {users.map((user) => (
        <div className="userChat" key={user.uid} onClick={() => handleSelect(user)}>
          <img src={user.photoURL} alt="" />
          <div className="userChatInfo">
            <span>{user.displayName}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Search;
