/* eslint-disable react/jsx-no-target-blank */
import '../General.css';
import './Footer.css';
import Newsletter from '../Newsletters/Newsletter';
import  Logo  from '../../assets/image/Datopia.jpeg'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer id="footer">

    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-md-6 footer-contact">
            <img className='daytopiafooterLogo' src={ Logo} alt='daytopia image' />
            <p>
            Join our network of experts and experienced Daytopia mentors to start learning critical real-life skills.
            </p>
          </div>

          <div className="col-lg-2 col-md-6 footer-links">
            <h4>About</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <Link to="/about">About us</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/about/daytopiafoundation">Daytopia Foundation</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/journals/sucessstories">Success Stories</Link></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Support</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <Link to="/about/contact">Contact</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/about/faq">FAQ</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/code-of-conduct">Code of Conduct</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/privacypolicy">Privacy Policy</Link></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Join Our Newsletter</h4>
            <p>Subscribe to get new updates from us</p>
           <Newsletter />
          </div>

        </div>
      </div>
    </div>

    <div className="container footer-down d-md-flex py-4">

      <div className="me-md-auto text-center text-md-start">
        <div className="copyright">
          &copy; Copyright <strong><span>Daytopia</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          Powered by <a href="https://developiq.org/">Develop-IQ Technologies</a>
        </div>
      </div>
      <div className="social-links text-center text-md-end pt-3 pt-md-0">
        <a href="https://x.com/Day_topia?t=_6i_ojqBYI1kAb5VEdiPSA&s=09/" target='_blank' className="twitter"><i className="bi bi-twitter"></i></a>
        <a href="#" target='_blank' className="facebook"><i className="bi bi-facebook"></i></a>
        <a href="https://www.instagram.com/daytopia_official?igsh=MTQ4c2dhNW1sd3cxMA==/" target='_blank' className="instagram"><i className="bi bi-instagram"></i></a>
        <a href="https://www.linkedin.com/company/daytopia-mentoring-program/" target='_blank' className="linkedin"><i className="bi bi-linkedin"></i></a>
      </div>
    </div>
  </footer>
  )
}

export default Footer