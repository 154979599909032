import NOAcees from '../../assets/gifs/no-access.gif';
import { Link, useNavigate } from 'react-router-dom';
import Opps from '../../assets/image/oops.png';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import './notfound.css';

const NoAccess = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is an admin
    const isAdmin = currentUser && currentUser.role === 'admin';

    // If the user is an admin, navigate them directly
    if (isAdmin) {
      navigate('/admin');
    }
  }, [currentUser, navigate]);

  return (
    <section id="noaccess">
      <div className="container">
        <div className="pageflex">
          <div className="pageflex-i no-access">
            <img src={NOAcees} alt="no-access" />
          </div>
          <div className="pagefle-ii">
            <img src={Opps} alt="Oops!" />
            <h3>YOU DO NOT HAVE ACCESS TO THIS PAGE...</h3>
            <p>THE PAGE YOU ARE LOOKING FOR MIGHT HAVE BEEN MOVED, RENAMED, OR MIGHT NEVER HAVE EXISTED.</p>
            <Link to='/'>Back Home</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoAccess;
