import { useState, useEffect, useContext } from "react";
import { db } from '../../firebase'; // Replace with your actual Firebase configuration import
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import Sidebar from "../sidebar";
import Navbar from "../Navbar";
import { useNavigate } from 'react-router-dom';
import '../admin.css';
import { AuthContext } from '../../context/AuthContext'; 


const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // Check the user's role and navigate to the home page if they are not an admin
    if (!currentUser || currentUser.role !== "admin") {
      navigate('/no-access');
    }
  }, [currentUser, navigate]);

  // Function to fetch subscriptions from Firebase Firestore
  const fetchSubscriptions = async () => {
    const subscriptionCollection = collection(db, 'subscriptions');
    const subscriptionSnapshot = await getDocs(subscriptionCollection);

    const subscriptionData = subscriptionSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setSubscriptions(subscriptionData);
  };

  // Function to delete a subscription by its ID
  const deleteSubscription = async (id) => {
    try {
      await deleteDoc(doc(db, 'subscriptions', id));
      console.log('Subscription deleted:', id);
      setSelectedSubscription(null);
      fetchSubscriptions(); // Refresh the subscription list after deletion
    } catch (error) {
      console.error('Error deleting subscription:', error);
    }
  };

  useEffect(() => {
    // Fetch subscriptions when the component loads
    fetchSubscriptions();
  }, []);

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, [])

  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    <Sidebar />
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    <Sidebar />
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
     <div className="admin">
      <h2>NewsLetters:</h2>
      <ol>
        {subscriptions.map((subscription) => (
          <li key={subscription.id}>
            {subscription.email}
            <button onClick={() => deleteSubscription(subscription.id)}>
              Delete
            </button>
          </li>
        ))}
      </ol>
    </div>
    </div>
    </div>
  );
};

export default Subscriptions;