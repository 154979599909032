import { NavLink } from 'react-router-dom';
import { useContext } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import './navchecker.css';

const LoginBtn = () => {
  const { currentUser } = useContext(AuthContext);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  }

  return (
    <div>
      <div className='loginbtnuser'>
        {currentUser ? (
          <>
            {currentUser.photoURL && <img src={currentUser.photoURL} alt="user Image" className='currenuserImage' />}
            <NavLink to="/" className="get-started-btn scrollto" onClick={handleSignOut}>Log out</NavLink>
          </>
        ) : (
          <p>Not logged in</p>
        )}
      </div>
    </div>
  )
}

export default LoginBtn;
