import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, query, getDocs, orderBy, limit } from "firebase/firestore";
import { db } from "../../firebase";


const RecentSuccessStory = () => {
  
    const [mostRecentStory, setMostRecentStory] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        const q = query(
          collection(db, "successStories"),
          orderBy("timestamp", "desc"),
          limit(1) // Limit to the most recent story
        );
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          // If there is a recent success story, set it as the most recent story
          const doc = querySnapshot.docs[0];
          const data = doc.data();
          setMostRecentStory({ ...data, id: doc.id });
        } else {
          setMostRecentStory(null);
        }
      };
  
      fetchData();
    }, []);
  

  return (
    <div>
    {mostRecentStory ? (
    <div className="post-items">
    <img  src={mostRecentStory.imageUrl} alt=" News details Image" className="flex-shrink-0" />
    <div>
    <Link to={`/journals/sucess-stories/sucesss-storiesdetails/${mostRecentStory.id}`}>
    <h4>{mostRecentStory.title}</h4>
  </Link>
    </div>
  </div>
  ) : (
    <p>No recent success stories available.</p>
  )}
  </div>
  )
}

export default RecentSuccessStory