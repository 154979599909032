/* eslint-disable react/jsx-no-target-blank */
import WorkShopAndSeminal from '../../assets/image/Daytopia_Workshop.jpeg';
import DaytoiaBlogAndNews from '../../assets/image/DAYTOPIA Investment Club.jpg';
import DaytopiaNetwork from '../../assets/image/daytopia_newtork.jpeg';
import DaytopiaFoundation from '../../assets/image/DaytopiaFoundation.jpg'
import { Link } from "react-router-dom";

const Homesections = () => {
  return (
    <div>
      <section id="HeaderSection-posts" className="HeaderSection-posts">
        <div className="container">
          <div className="row gy-4">
         <div
              className="col-xl-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <article>
                 <Link to="/about"><div className="post-img">
                 
                    <img src={DaytopiaNetwork} alt="Daytopia Network Image " className="img-fluid" />
                 
                </div>

                <h2 className="title">Daytopia Mentoring Network </h2>
                <p className="post-category">
                Learn more about the six pillars of the Daytopia Mentoring Program.
                </p>
              </Link>  </article>
             
            </div>


         <div
              className="col-xl-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <article>
              <Link to="/daytopia-shop/daytopia-investment-club">
                <div className="post-img">
                 
                    <img src={DaytoiaBlogAndNews} alt="Daytopia Investment Club" className="img-fluid" />
               
                </div>

                <h2 className="title">Daytopia Investment Club. </h2>
                <p className="post-category">
               Check Our Investment Club
                </p>
                </Link>
              </article>
            </div>
       
         <div
              className="col-xl-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <article>
              <a href="https://www.youtube.com/@daytopiatv" target="_blank">
                <div className="post-img">
                 
                    <img src={WorkShopAndSeminal} alt="daytopia workshop and seminals Image" className="img-fluid" />
                 
                </div>

                <h2 className="title">Daytopia Workshops and Seminars.</h2>
                <p className="post-category">
                Check our youtube channel for our Workshops and Seminars
                </p> </a>
              </article>
            </div>
         <div
              className="col-xl-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <article>
              <Link to="/about/daytopiafoundation" target="_blank">
                <div className="post-img">
                 
                    <img src={DaytopiaFoundation} alt="daytopia workshop and seminals Image" className="img-fluid" />
                 
                </div>

                <h2 className="title">Daytopia Foundation.</h2>
                <p className="post-category">
                Learn more how we establish sponsorship towards certifications and self-development
                </p> </Link>
              </article>
            </div>
         
          </div>
        </div>
      </section>
    </div>
  );
};

export default Homesections;
