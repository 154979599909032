import  { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { auth } from "../../firebase"; // Import your authentication reference
import { Button,  TextField } from "@mui/material"; // You can use Material-UI for the dialog
import './profile.css';

const ProfileDescription = () => {
  const [description, setDescription] = useState("");
  const [savedDescription, setSavedDescription] = useState("");
  const [isEditing, setIsEditing] = useState(false); // Track if the user is in edit mode
  const user = auth.currentUser; // Get the authenticated user

  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, "profileDescription", user.uid); // Replace with your Firestore collection name

      getDoc(userDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setDescription(userData.description);
            setSavedDescription(userData.description);
          }
        })
        .catch((error) => {
          console.error("Error fetching description:", error);
        });
    }
  }, [user]);

  const handleSave = async () => {
    if (user) {
      const userDocRef = doc(db, "profileDescription", user.uid);

      try {
        await setDoc(userDocRef, { description });
        setSavedDescription(description);
        setIsEditing(false); // Exit edit mode after saving
        console.log("Description saved:", description);
      } catch (error) {
        console.error("Error saving description:", error);
      }
    }
  };

  return (
    <div>
    <section className='profilesection'>
    <div className='container'>
    <div className="profiledescription">
      <h2>Describe yourself</h2>
      <div className="profiledescription-child">
        {isEditing ? ( // If in edit mode, show a text field and Save button
          <div>
            <TextField
              multiline
              rows={4}
              placeholder="Enter your description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </div>
        ) : (
          // If not in edit mode, show the saved description and Edit button
          <div className="profiledescription-p">
            <p>{savedDescription}</p>
            <Button variant="contained" color="primary" onClick={() => setIsEditing(true)}>
              Edit
            </Button>
          </div>
        )}
      </div>
      </div>
      </div>
      </section>
    </div>
  );
};

export default ProfileDescription;
