import { useEffect, useState } from 'react';
import { auth } from '../../firebase';

const EmailVerificationBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if the user is signed in
    const user = auth.currentUser;

    if (user && !user.emailVerified) {
      // If the user is signed in but email is not verified, show the banner
      setShowBanner(true);
    } else {
      // If the user is signed in and email is verified, or if the user is not signed in, hide the banner
      setShowBanner(false);
    }
  }, []);

  const handleBannerClose = () => {
    // Close the banner and set a flag in local storage to prevent it from showing on subsequent refreshes
    setShowBanner(false);
    localStorage.setItem('hideVerificationBanner', 'true');
  };

  useEffect(() => {
    // Check local storage flag to determine whether to show the banner
    const hideVerificationBanner = localStorage.getItem('hideVerificationBanner');

    if (!hideVerificationBanner && auth.currentUser && !auth.currentUser.emailVerified) {
      setShowBanner(true);
    }
  }, []);

  if (!showBanner) {
    return null; // If the banner shouldn't be displayed, return null
  }

  return (
    <div className="verification-banner">
      <p>Your email is not verified. Please check your email to complete the sign-up process.</p>
      <button onClick={handleBannerClose}>Close</button>
    </div>
  );
};

export default EmailVerificationBanner;
