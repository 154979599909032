/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { db, auth } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";


const CalendlyWidget = () => {
    const [userData, setUserData] = useState({});

    useEffect(() => {
      // Get the user's UID from Firebase authentication
      const user = auth.currentUser;
      
      if (user) {
        // Reference the user's document in the Firestore collection
        const userDocRef = doc(db, "users", user.uid);
        
        // Listen for changes to the user document in Firestore
        const unsubscribe = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            setUserData(doc.data());
          }
        });
        
        // Clean up the listener when the component unmounts
        return () => unsubscribe();
      }
    }, []);
  

  return (
    <div className="book-a-meeting">
      <a target="_blank" href={userData.calendlyLink}>
        <p>Book A Meeting</p>
       <i className="bi bi-bell-fill"></i>
    </a>

    </div>
  );
};

export default CalendlyWidget;
