import { useState } from "react";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import './Forgotpassword.css';
import Navbar from "../../Components/Header/Navbar";
import Footer from "../../Components/Footer/Footer";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent. Check your inbox.");
    } catch (error) {
      setMessage("Error sending the password reset email. Please check your email address.");
    }
  };

  return (
    <div>
    <Navbar />
    <section className="h-100 bg-light">
    <div className="container py-5 h-100">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-md-6">
          <div className="card card-registration">
            <div className="card-body p-md-5 text-dark">
            <p className="mb-5 text-uppercase">Reset your password</p>
      <form onSubmit={handlePasswordReset}>
        <div className="mb-3 reset_input">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type="submit" className="btn signinbtn_reset btn-primary">
          Reset Password
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
    </div>
    </div>
    </div>
    </div>
    </section>
    <Footer />
    </div>
  );
};

export default PasswordReset;
