import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db, auth } from "../../../firebase";
import './BooksandPublications.css';

const Books = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const booksCollection = collection(db, "books");
        const booksSnapshot = await getDocs(booksCollection);
        const booksData = booksSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBooks(booksData);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };

    fetchBooks();
  }, []);

  const handleEmailClick = (email, title) => {
    const subject = encodeURIComponent(`Book Inquiry: ${title}`);
    const body = encodeURIComponent(`Hello Daytopia,\n\nI am interested in the book "${title}".\n\nThank you!`);
    const mailtoURL = `mailto:${email}?subject=${subject}&body=${body}`;

    // Open the default email client
    window.location.href = mailtoURL;
  };

  return (
    <div>
      <section id="books" className="books">
        <div className="container section-title" data-aos="fade-up">
          <h2>Books</h2>
          <p>Get Our Books here</p>
        </div>

        <div className="container">
          <div
            className="isotope-layout"
            data-default-filter="*"
            data-layout="masonry"
            data-sort="original-order"
          >
            <div
              className="row gy-4 isotope-container"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {books.map((book) => (
                <div
                  key={book.id}
                  className="col-lg-4 col-md-6 books-item isotope-item filter-app"
                >
                  <img src={book.imageUrl} alt={book.title} className="img-fluid" />
                  <div className="books-info">
                    <h4>{book.title}</h4>
                    <div className="price-Download">
                    {auth.currentUser ? (
                      <button
                        onClick={() => handleEmailClick("admin@daytopiagroup.com", book.title)}
                        >
                        Download
                      </button>
                    ) : (
                      <p>Please sign in to download</p>
                    )}
                    <h5>{book.price}</h5>
                  </div>
                  
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Books;
