import {
  RouterProvider,
} from "react-router-dom";

import { router } from "./route";
import WhatsappIcon from "./Components/whatsappIcon/WhatsappIcon";

function App() {
  return (
    <div>
      <RouterProvider router={router} />
      <WhatsappIcon />
    </div>
  );
}

export default App;
