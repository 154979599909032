import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './CodeOfConduct.css';

const Contact = () => {
  const form = useRef();
  const [buttonText, setButtonText] = useState('Send Message');

  const sendEmail = (e) => {
    e.preventDefault();

    // Change the button text to "Sending..."
    setButtonText('Sending...');

    emailjs.sendForm('service_l63jszq', 'template_3ps8rzj', form.current, 'mOnPJs2SL-vGe3WDS') 
      .then((result) => {
        console.log(result.text);
        // Clear the form fields after a successful submission
        form.current.reset();
        // Change the button text to "Sent"
        setButtonText('Sent');
        // Reset the button text to "Send Message" after a delay (e.g., 2 seconds)
        setTimeout(() => setButtonText('Send Message'), 2000);
      })
      .catch((error) => {
        console.log(error.text);
        // Change the button text back to "Send Message" in case of an error
        setButtonText('Send Message');
      });
  };

  return (
    <div>
    <section id="contact" className="contact">
    <div className="container" data-aos="fade-up">

      <div className="section-header">
        <h2>Contact</h2>
        <p>
        Do you have a question about the Graduate Mentor platform? We would love to hear from you…
        </p>
      </div>

      <div className=" gx-lg-0 gy-4">
        <div className="col-lg-8">
          <form ref={form} onSubmit={sendEmail} className="php-email-form">
            <div className="row">
              <div className="col-md-6 form-group">
                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
              </div>
              <div className="col-md-6 form-group mt-3 mt-md-0">
                <input type="text" className="form-control" name="name" id="lastname" placeholder="Last Name" required />
              </div>
            </div>
            <div className="form-group mt-3">
              <input type="email" className="form-control" name="email" id="email" placeholder="Email" required />
            </div>
            <div className="form-group mt-3">
              <textarea className="form-control" name="message" rows="7" placeholder="Message" required></textarea>
            </div>

            <div className="text-center">
            <button type="submit" value="send">{buttonText}</button>
            </div>
          </form>
        </div>

      </div>

    </div>
  </section>
    </div>
  )
}

export default Contact
