import { useEffect, useState, useContext } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import Sidebar from '../sidebar';
import Navbar from '../Navbar';
import { useNavigate } from 'react-router-dom';
import '../admin.css';
import { AuthContext } from '../../context/AuthContext';

const FetchMentorData = () => {
  const [mentorData, setMenteeData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // Check the user's role and navigate to the home page if they are not an admin
    if (!currentUser || currentUser.role !== 'admin') {
      navigate('/no-access');
    }
  }, [currentUser, navigate]);

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const fetchMenteeData = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const mentorQuery = query(usersCollection, where('role', '==', 'mentor'));
        const mentorSnapshot = await getDocs(mentorQuery);

        const mentorDataArray = [];
        mentorSnapshot.forEach((doc) => {
          const mentorUserData = doc.data();
          mentorDataArray.push({
            id: doc.id, // Add document ID to use it for deletion
            name: mentorUserData.displayName,
            email: mentorUserData.email,
            whatsappNumber: mentorUserData.whatsappNumber,
          });
        });

        setMenteeData(mentorDataArray);
      } catch (error) {
        console.error('Error fetching mentor data:', error);
      }
    };

    fetchMenteeData();
  }, []);

  const handleDeleteMentee = async (id) => {
    try {
      // Delete the mentor document from Firestore
      await deleteDoc(doc(db, 'users', id));

      // Update the state to reflect the changes
      setMenteeData((prevMenteeData) =>
        prevMenteeData.filter((mentor) => mentor.id !== id)
      );
    } catch (error) {
      console.error('Error deleting mentor:', error);
    }
  };

  return (
    <div className="d-flex">
      <div className={toggle ? 'd-none' : 'w-auto position-fixed'}>
        <Sidebar />
      </div>

      <div className={toggle ? 'd-none' : 'invisible'}>
        <Sidebar />
      </div>

      <div className="col">
        <Navbar Toggle={Toggle} />
        <div className="admin">
          <h1>
            Mentor's <span className="admintitle">Panel</span>
          </h1>
          <div className="mentor-container">
            <ol className='mentor-container-List'>
              {mentorData.map((mentor, index) => (
                <li key={index}>
                  <strong>Name:</strong> {mentor.name}, <strong>Email:</strong> {mentor.email},{' '}
                  <strong>WhatsApp Number:</strong> {mentor.whatsappNumber}
                  <button onClick={() => handleDeleteMentee(mentor.id)}>Delete</button>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FetchMentorData;
