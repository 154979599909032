
const Breadcumbs = () => {
  return (
    <div>
       <main id='main'>
       <div className="breadcrumbs d-flex align-items-center breadcrumbs-contact">
         <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
   
           <h2>Contact</h2>
   
         </div>
       </div>
   
       </main>
    </div>
  )
}

export default Breadcumbs
