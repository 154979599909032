import UserInfo from './UserInfo';
import '../admin.css';;
import Navbar from '../Navbar';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../context/AuthContext';

const Users = () => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);


  useEffect(() => {
    // Check the user's role and navigate to the home page if they are not an admin
    if (!currentUser || currentUser.role !== "admin") {
      navigate('/no-access');
    }
  }, [currentUser, navigate]);

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    };

    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize);
    };

  }, []);


  return (
    <div className="d-flex">
      <div className={toggle ? "d-none" : "w-auto position-fixed"}>
        <Sidebar />
      </div>

      <div className={toggle ? "d-none" : "invisible"}>
        <Sidebar />
      </div>

      <div className='col'>
        <Navbar Toggle={Toggle} />
        <UserInfo />
      </div>
    </div>
  )
}

export default Users;
