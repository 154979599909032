import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, query, getDocs, orderBy, limit } from "firebase/firestore";
import { db } from "../../firebase";

const RecentSuccessStory = () => {
  const [mostRecentStory, setMostRecentStory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(db, "successStories"),
        orderBy("timestamp", "desc"),
        limit(1) // Limit to the most recent story
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If there is a recent success story, set it as the most recent story
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        setMostRecentStory({ ...data, id: doc.id });
      } else {
        setMostRecentStory(null);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row gy-4 posts-list">
            {mostRecentStory ? (
              <div className="col-xl-10 col-md-10">
                <div className="post-item position-relative h-100">
                  <div className="post-img position-relative overflow-hidden">
                    <img
                      src={mostRecentStory.imageUrl}
                      alt="Success Story Image"
                      className="img-fluid"
                    />
                    <span className="post-date">Success Story</span>
                  </div>

                  <div className="blog-logo">
                    <h3>DG</h3>
                  </div>

                  <div className="post-content d-flex flex-column">
                    <Link to={`/journals/sucess-stories/sucesss-storiesdetails/${mostRecentStory.id}`}>
                      <h3 className="post-title">{mostRecentStory.title}</h3>
                    </Link>
                    <div
                    className="content blog-content"
                    dangerouslySetInnerHTML={{ __html: mostRecentStory.content.slice(0, 300) }}
                  />
                  
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-xl-4 col-md-6">
                <p>No recent success stories available.</p>
              </div>
            )}
          </div>
        </div>
      </section>

    </div>
  );
};

export default RecentSuccessStory;
