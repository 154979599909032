import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Hero from './Hero';
import Homesection from './Homesections';
import CEOword from './CEOWord';
import Sidebar from '../../Components/Sidebar/sidebar';
import GeneralRecent from '../../Components/Recent/GeneralRecent';
import { useState, useEffect } from 'react';
import '../../Components/Sidebar/sidebar.css';
import Navbar from '../../Components/Header/Navbar';
import Footer from '../../Components/Footer/Footer';
import { Helmet } from 'react-helmet-async';
import { HelmetProvider } from 'react-helmet-async';
import Slide1 from '../../assets/image/one-to-one.webp';
import Prompt from '../../Components/prompt/Prompt';

const Home = () => {
  const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 
  return (
    <>
   <HelmetProvider>
        <Helmet>
          <title>Home - Default Blog Title</title>
          <meta name="description" content="Default Blog Description" />
          {/* Use static values for testing */}
          <meta property="og:title" content="My Static Title" />
          <meta property="og:description" content="My Static Description" />
          <meta property="og:image" content={Slide1} />
        </Helmet>
      </HelmetProvider>

    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
      <Hero />
      <Homesection />
      <Prompt />
      <CEOword />
      <GeneralRecent />
      <Footer />
      </div>
    </div>
    </>
  )
}

export default Home
