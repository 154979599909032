import Breadcrumbs from './Breadcrumbs';
import Mission from './Mission';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import GauranteeResearch from './GauranteeResearch';
import Sidebar from '../../../Components/Sidebar/sidebar';
import MentorResearch from './MentorResearch';
import Banner from './Banner';
import { useState, useEffect } from 'react';
import '../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../Components/Header/Navbar';
import Footer from '../../../Components/Footer/Footer';
import '../../../Components/Sidebar/sidebar.css'
import DownloadFIle from './DownloadFIle';
import Introdction from './Introdction';
import { Helmet } from 'react-helmet-async';
import { HelmetProvider } from 'react-helmet-async';
import TrustessCarousel from '../DaytopiaFoundations/TrustessCarousel';
import TeamCarousel from '../DaytopiaFoundations/TeamCarousel';

const About = () => {
  const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 
  return (
    <>
     <HelmetProvider>
        <Helmet>
          <title>Home - Default Blog Title</title>
          <meta name="description" content="Default Blog Description" />
          {/* Use static values for testing */}
          <meta property="og:title" content="My Static Title" />
          <meta property="og:description" content="My Static Description" />
          {/* <meta property="og:image" content={Slide1} /> */}
        </Helmet>
      </HelmetProvider>
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
      <Breadcrumbs />
      <Introdction />
      <Mission />
      <GauranteeResearch />
      <DownloadFIle />
      <TeamCarousel />
      <Banner />
      <Footer />
      </div>
    </div>
    </>
  )
}

export default About
