import { useState, useEffect, useContext } from "react";
import {
  collection,
  addDoc,
  deleteDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { db } from "../../firebase";
import Sidebar from "../sidebar";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import "../admin.css";
import { AuthContext } from "../../context/AuthContext";

const CarrerPost = () => {
  const [companyImage, setCompanyImage] = useState("");
  const [location, setLocation] = useState("");
  const [carrerName, setJobName] = useState("");
  const [skills, setSkills] = useState("");
  const [carrerType, setJobType] = useState("");
  const [carrerDuration, setJobDuration] = useState("");
  const [amount, setAmount] = useState("");
  const [carrerListings, setJobListings] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [applyUrl, setApplyUrl] = useState("");
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser || currentUser.role !== "admin") {
      navigate("/no-access");
    }
  }, [currentUser, navigate]);

  const fetchJobListings = async () => {
    const carrerListingsCollection = collection(db, "carrerListings");
    const querySnapshot = await getDocs(carrerListingsCollection);
    const carrerListingsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setJobListings(carrerListingsData);
  };

  const handleImageUpload = (e) => setCompanyImage(e.target.files[0]);

  const handleCreateJob = async () => {
    if (
      !companyImage ||
      !location ||
      !carrerName ||
      !skills ||
      !carrerType ||
      !carrerDuration ||
      !amount
    ) {
      alert("Please fill in all fields.");
      return;
    }

    const storage = getStorage();
    const storageRef = ref(storage, `images/${companyImage.name}`);
    await uploadBytes(storageRef, companyImage);
    const imageUrl = await getDownloadURL(storageRef);

    const carrerListingData = {
      companyImage: imageUrl,
      location,
      carrerName,
      skills,
      carrerType,
      carrerDuration,
      amount,
      applyUrl,
    };

    const carrerListingsCollection = collection(db, "carrerListings");
    await addDoc(carrerListingsCollection, carrerListingData);

    setCompanyImage(null);
    setLocation("");
    setJobName("");
    setSkills("");
    setJobType("");
    setJobDuration("");
    setAmount("");
    setApplyUrl("");

    fetchJobListings();
  };

  const deleteJobPosting = async (carrerId, carrerName) => {
    const carrerListingDoc = doc(db, "carrerListings", carrerId);
    await deleteDoc(carrerListingDoc);
    fetchJobListings();
    alert(`Deleted carrer listing: ${carrerName}`);
  };

  useEffect(() => {
    fetchJobListings();
  }, []);

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    };
    window.addEventListener("resize", handleSize);

    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  return (
    <div className="d-flex">
      <div className={toggle ? "d-none" : "w-auto position-fixed"}>
        <Sidebar />
      </div>

      <div className={toggle ? "d-none" : "invisible"}>
        <Sidebar />
      </div>

      <div className="col">
        <Navbar Toggle={Toggle} />
        <div className="admin">
          <h1>
            Carrer <span className="admintitle">Post</span>
          </h1>
          <div>
          <label>Job Company Logo</label>
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={handleImageUpload}
          /></div>
          <input
            type="text"
            placeholder="Job Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <input
            type="text"
            placeholder="Job Name"
            value={carrerName}
            onChange={(e) => setJobName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Job Skills (comma-separated)"
            value={skills}
            onChange={(e) => setSkills(e.target.value)}
          />
          <input
            type="text"
            placeholder="Job Description"
            value={carrerType}
            onChange={(e) => setJobType(e.target.value)}
          />
          <input
            type="text"
            placeholder="Job Duration (month, week, hr)"
            value={carrerDuration}
            onChange={(e) => setJobDuration(e.target.value)}
          />
          <input
            type="text"
            placeholder="Job Amount or Salary"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <input
            type="text"
            placeholder="Job Apply Now URL"
            value={applyUrl}
            onChange={(e) => setApplyUrl(e.target.value)}
          />
          <div className="filteJobr_admin">
          <label>Job Type Filter</label>
          <select value={carrerType} onChange={(e) => setJobType(e.target.value)}>
          <option value="Finance">internship</option>
          <option value="Software Development">volunteering</option>
          <option value="Teaching">paid full time job</option>
            {/* Add other carrer sectors as needed */}
          </select>
          </div>
          <button onClick={handleCreateJob}>Create Job Listing</button>

          {carrerListings.map((carrer) => (
            <div key={carrer.id}>
              <img src={carrer.companyImage} alt="Company Logo" />
              <p className="carrerfetchs">Location: {carrer.location}</p>
              <p>Job Name: {carrer.carrerName}</p>
              <p className="carrerfetchs">Skills: {carrer.skills}</p>
              <p className="carrerfetchs">Job Type: {carrer.carrerType}</p>
              <p className="carrerfetchs">Job Duration: {carrer.carrerDuration}</p>
              <p className="carrerfetchs">Amount: {carrer.amount}</p>
              <p className="carrerfetchs">Apply Now URL: {carrer.applyUrl}</p>
              <button onClick={() => deleteJobPosting(carrer.id, carrer.carrerName)}>
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarrerPost;
