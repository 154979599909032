import RecentBlog from "./RecentBlog";
import RecentNews from "./RecentNews";
import RecentSuccessStory from "./RecentSucessStories";
import './GeneralRecent.css'

const GeneralRecent = () => {
    return (
      <div>
      <section id="general-recent" className="general-recent">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="generalrecent">
            <div className="generalrecentChild">
              <RecentBlog className="generalrecentChild"/>
              </div>
              <div className="generalrecentChild">
              <RecentNews />
              </div>
              <div className="generalrecentChild">
              <RecentSuccessStory />
              </div>
              </div>
        </div>
      </section>


      
      </div>
    );
  };

  export default GeneralRecent