import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, query, getDocs, orderBy, limit } from "firebase/firestore";
import { db } from "../../firebase";

const RecentBlog = () => {
    const [mostRecentPost, setMostRecentPost] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        const q = query(
          collection(db, "blogPosts"),
          orderBy("timestamp", "desc"),
          limit(1) // Limit to the most recent post
        );
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          // If there is a recent blog post, set it as the most recent post
          const doc = querySnapshot.docs[0];
          const data = doc.data();
          setMostRecentPost({ ...data, id: doc.id });
        } else {
          setMostRecentPost(null);
        }
      };
  
      fetchData();
    }, []);

  return (
    <div>
    {mostRecentPost ? (
    <div key={mostRecentPost.id} className="post-items">
    <img src={mostRecentPost.imageUrl} alt="Blog details Image" className="flex-shrink-0" />
    <div>
    <Link to={`/journals/blog/blogdetails/${mostRecentPost.id}`}>
    <h4>{mostRecentPost.title}</h4>
  </Link>
    </div>
  </div>
  ) : (
    <p>No recent blog available.</p>
  )}
  </div>
  )
}

export default RecentBlog