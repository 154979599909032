import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db, auth } from "../../../firebase";
import { Link } from "react-router-dom";
import "../../JobList/Job.css";
import Logo from '../../../assets/image/Datopia.jpeg'

const CarrerFetch = () => {
  const [carrerListings, setJobListings] = useState([]);
  const [carrerNameFilter, setJobNameFilter] = useState("");
  const [carrerTypeFilter, setJobTypeFilter] = useState("");

  const fetchJobListings = async () => {
    try {
      const carrerListingsCollection = collection(db, "carrerListings");
      const querySnapshot = await getDocs(carrerListingsCollection);
      const carrerListingsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Apply filters during fetching
      const filteredListings = carrerListingsData
        .filter((carrer) =>
          carrer.carrerName.toLowerCase().includes(carrerNameFilter.toLowerCase())
        )
        .filter((carrer) =>
          carrerTypeFilter === "" || carrer.carrerType === carrerTypeFilter
        );

      setJobListings(filteredListings);
    } catch (error) {
      console.error("Error fetching carrer listings:", error);
    }
  };

  const handleJobNameFilterChange = (e) => {
    setJobNameFilter(e.target.value);
  };

  useEffect(() => {
    fetchJobListings();
  }, [carrerNameFilter, carrerTypeFilter]);

  // const handleCarrerClick = (email, carrerName) => {
  //   const subject = encodeURIComponent(`Book Inquiry: ${carrerName}`);
  //   const body = encodeURIComponent(`Hello Daytopia,\n\nI want to apply for the job post "${carrerName}".\n\nThank you!`);
  //   const mailtoURL = `mailto:${email}?subject=${subject}&body=${body}`;

   
  //   window.location.href = mailtoURL;
  // };



  return (
    <div className="container">
      <section className="Job">
        <div className="container">
          <div className="filter-section">
          <div className="filterCont">
          
            <label>Filter Name:</label>
            <input
              type="text"
              value={carrerNameFilter}
              onChange={handleJobNameFilterChange}
            /></div>
            <div className="filterCont">
            <label>Filter Type:</label>
            <select
              value={carrerTypeFilter}
              onChange={(e) => setJobTypeFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="Finance">internship</option>
              <option value="Software Development">volunteering</option>
              <option value="Teaching">paid full time job</option>
              {/* Add other carrer sectors as needed */}
            </select>
          </div>
          </div>
          <div className="row">
            {carrerListings
              .filter((carrer) =>
                carrer.carrerName.toLowerCase().includes(carrerNameFilter.toLowerCase())
              )
              .filter((carrer) =>
                carrerTypeFilter === "" || carrer.carrerType === carrerTypeFilter
              )
              .map((carrer) => (
                <div
                  key={carrer.id}
                  className="col-md-6 col-lg-4 d-flex align-items-stretch"
                  data-aos="fade-up"
                >
                  <div className="icon-box icon-box-pink carrer-board-container">
                    <div>
                      <div className="job-board-flex-1">
                        <div className="job-board-flex-1-child1">
                          <div className="job-board-flex-inner-1">
                            <img src={ Logo } alt="Daytoia Logo"/>
                          </div>

                          <div className="job-board-flex-inner-2">
                            <h6>{carrer.location}</h6>
                          </div>
                        </div>

                        <div className="job-board-flex-1-child2">
                          <h2>DT</h2>
                        </div>
                      </div>

                      <h1 className="job-link">
                       {carrer.carrerName}
                      </h1>
                      <div className="job-detail-time">
                       

                        <div className="job-detail-2"></div>
                      </div>

                      <p>{carrer.carrerType}</p>

                      <div className="job-apply">
                        {/* <div className="job-apply-pay">
                          <h3>
                            <span className="job-apply-span">{carrer.amount}</span>
                            /Hour
                          </h3>
                        </div> */}

                        <div className="job-button">
                          {auth.currentUser ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={carrer.applyUrl}
                            >
                              Apply Now
                            </a>
                          ) : (
                            <Link to="/auth/signin">Sign in to Apply</Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CarrerFetch;
