import { useState } from 'react';
import Profiledetails from "./Profiledetails";
import ProfileDescription from './ProfileDescription';
import ChangeNameAndPassword from './ChangeName';
import ChangePassword from './ChangePassord';

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState('profile'); // 'profile' or 'setting'

  return (
    <div className='container'>
      <div className="tabs-container_filter"> 
        <button onClick={() => setActiveTab('profile')}>Profile</button>
        <button onClick={() => setActiveTab('setting')}>Setting</button>
      </div>

      {activeTab === 'profile' && (
        <div className="profiledeatilscontainer-filter">
        
        <h3 className="title">Profile</h3>
          {/* Your Profile component content */} 
          <Profiledetails />
        <ProfileDescription />
        </div>
      )}

      {activeTab === 'setting' && (
        <div className="profiledeatilscontainer">
        <h3 className="title">Settings</h3>
          {/* Your Setting component content */}
          <ChangeNameAndPassword />
          <ChangePassword />
        </div>
      )}
    </div>
  );
};

export default UserProfile;
