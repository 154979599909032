import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase'; // Replace with your actual Firebase configuration import

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [buttonText, setButtonText] = useState('Subscribe');

  const handleSubscribe = async (e) => {
    e.preventDefault();

    // Change the button text to "Subscribing..."
    setButtonText('Subscribing...');

    try {
      // Add the email and a timestamp to Firebase Firestore
      const docRef = await addDoc(collection(db, 'subscriptions'), {
        email: email,
        timestamp: serverTimestamp(),
      });

      console.log('Subscription successful. Document ID:', docRef.id);
    } catch (error) {
      console.error('Error subscribing:', error);
    }

    setEmail(''); // Clear the email input after submission
    // Reset the button text to "Subscribe" after a delay (e.g., 2 seconds)
    setTimeout(() => setButtonText('Subscribe'), 2000);
  };

  return (
    <div>
      <form onSubmit={handleSubscribe}>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input type="submit" value={buttonText} />
      </form>
    </div>
  );
};

export default Newsletter;
