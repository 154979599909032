import { doc, getDoc } from "firebase/firestore"
import { db } from "../firebase"

export const getUserDetails = async (userId) => {
    // this function would be used to get the user details

    var userDetailsRef = doc(db, "users", userId);

    const userDetails = await getDoc(userDetailsRef)

    // Return the user details.
    // The recipients of this function can check if the user exists
    return userDetails;
}



export const getUsersChatRoomId = (firstUser, secondUser) => {
    // This function would look to get the id of the chat of the users
    // It sorts the user ids

    const userIds = [firstUser, secondUser]
    userIds.sort()

    return `${userIds[0]}_${userIds[1]}`;

}//


export default { getUsersChatRoomId, getUserDetails }
