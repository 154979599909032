import React, { useState, useEffect } from 'react';
import MentorSearch from './MentorSearch';
import { Link } from 'react-router-dom';
import { collection, query, where, getDocs, doc, updateDoc, setDoc, getDoc, arrayUnion, Timestamp } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { Button, Modal } from 'react-bootstrap';
import { serverTimestamp } from 'firebase/firestore';
import { chatsCollection, userChatsCollection } from '../../Utils/collection_names_config';
import { getUsersChatRoomId } from '../../Utils/firebase_helper_functions';
import { v4 as uuid } from 'uuid';
import Calendly from '../../Components/calendly/Calendly';
import Skeleton from 'react-loading-skeleton';
import './Mentors.css';

const MentorsListing = () => {
  const [mentors, setMentors] = useState([]);
  const [filteredMentors, setFilteredMentors] = useState([]);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [mentorProfiles, setMentorProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [sendingMentor, setSendingMentor] = useState(null);

  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersRef = collection(db, 'users');
        const roleQuery = query(usersRef, where('role', '==', 'mentor'));
        const querySnapshot = await getDocs(roleQuery);
        const mentorList = [];
        const mentorProfilesData = [];

        for (const docSnap of querySnapshot.docs) {
          const user = docSnap.data();
          if (user && docSnap.id) {
            const userId = docSnap.id;
            mentorList.push(user);
            const profileDescRef = doc(db, 'profileDescription', userId);
            const profileDescSnap = await getDoc(profileDescRef);
            if (profileDescSnap.exists()) {
              mentorProfilesData.push({
                userId,
                profileDescription: profileDescSnap.data().description,
              });
            }
          }
        }

        setMentors(mentorList);
        setFilteredMentors(mentorList);
        setMentorProfiles(mentorProfilesData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const fetchMentors = async () => {
    try {
      const q = query(collection(db, 'users'), where('role', '==', 'mentor'), where('calendlyLink', '!=', null));
      const querySnapshot = await getDocs(q);
      const mentorList = querySnapshot.docs.map((doc) => doc.data());
      setMentors(mentorList);
      setFilteredMentors(mentorList);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching mentors:', error);
    }
  };

  useEffect(() => {
    fetchMentors();
  }, []);

  useEffect(() => {
    const fetchMentorProfiles = async () => {
      const usersRef = collection(db, 'users');
      const roleQuery = query(usersRef, where('role', '==', 'mentor'));

      try {
        const querySnapshot = await getDocs(roleQuery);
        const mentorProfilesData = [];

        for (const docSnap of querySnapshot.docs) {
          const user = docSnap.data();

          if (user && docSnap.id) {
            const userId = docSnap.id;
            const profileDescRef = doc(db, 'profileDescription', userId);

            try {
              const profileDescSnap = await getDoc(profileDescRef);

              if (profileDescSnap.exists()) {
                mentorProfilesData.push({
                  userId,
                  profileDescription: profileDescSnap.data().description,
                });
              }
            } catch (error) {
              console.error('Error fetching profile description:', error);
            }
          }
        }

        setMentorProfiles(mentorProfilesData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching mentor profiles:', error);
        setLoading(false);
      }
    };

    fetchMentorProfiles();
  }, []);

  const openModal = (mentor) => {
    setSelectedMentor(mentor);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedMentor(null);
    setShowModal(false);
  };

  const handleSendMessage = async () => {
    if (!currentUser) {
      window.location.href = '/auth/signin';
      return;
    }

    if (messageText && selectedMentor) {
      try {
        setSending(true);
        setSendingMentor(selectedMentor);

        const chatRoomId = getUsersChatRoomId(selectedMentor.uid, currentUser.uid);
        const chatRef = doc(db, chatsCollection, chatRoomId);
        const chatSnapshot = await getDoc(chatRef);

        if (chatSnapshot.exists()) {
          await updateDoc(doc(db, chatsCollection, chatRoomId), {
            messages: arrayUnion({
              id: uuid(),
              text: messageText,
              senderId: currentUser.uid,
              date: Timestamp.now(),
            }),
          });
        } else {
          await setDoc(chatRef, {
            messages: [
              {
                id: uuid(),
                text: messageText,
                senderId: currentUser.uid,
                date: Timestamp.now(),
              },
            ],
          });
        }

        let currentUserDetails = await getDoc(doc(db, userChatsCollection, currentUser.uid));

        if (!currentUserDetails.exists()) {
          await setDoc(doc(db, userChatsCollection, currentUser.uid), {});
        }

        await updateDoc(doc(db, userChatsCollection, currentUser.uid), {
          [chatRoomId + '.lastMessage']: {
            messageText,
          },
          [chatRoomId + '.date']: serverTimestamp(),
          [chatRoomId + '.userInfo']: {
            uid: selectedMentor.uid,
            displayName: selectedMentor.displayName,
            photoURL: selectedMentor.photoURL,
          },
        });

        let selectedMentorDetails = await getDoc(doc(db, userChatsCollection, selectedMentor.uid));

        if (!selectedMentorDetails.exists()) {
          await setDoc(doc(db, userChatsCollection, selectedMentor.uid), {});
        }

        await updateDoc(doc(db, userChatsCollection, selectedMentor.uid), {
          [chatRoomId + '.lastMessage']: {
            messageText,
          },
          [chatRoomId + '.date']: serverTimestamp(),
          [chatRoomId + '.userInfo']: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
          },
        });

        setMessageText('');
        closeModal();
        console.log('Sending message...');
      } catch (error) {
        console.error('Error sending the message:', error);
      } finally {
        setSending(false);
        setSendingMentor(null);
      }
    }
  };

  return (
    <section id="mentor" className="mentor section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Find your Mentors</h2>
        </div>

        <div>
          <MentorSearch mentors={mentors} setFilteredMentors={setFilteredMentors} />
        </div>

        <div className="row">
          {loading &&
            Array.from({ length: 6 }).map((_, index) => (
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch" key={index}>
                <div className="member" data-aos="fade-up" data-aos-delay="100">
                  <div className="member-img mentor_general">
                    <Skeleton height={200} />
                  </div>
                  <div className="member-info">
                    <h4>
                      <Skeleton width={100} />
                    </h4>
                    <p>
                      <Skeleton count={3} />
                    </p>
                    <div className='mentor-calendly_container'>
                      <Skeleton width={100} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {!loading &&
            filteredMentors.map((mentor) => (
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch" key={mentor.uid}>
                <div className="member" data-aos="fade-up" data-aos-delay="100">
                  <div className="member-img mentor_general-Img">
                    <img src={mentor.photoURL} alt={mentor.displayName} />
                  </div>
                  <div className="member-info">
                    <h4>{mentor.displayName}</h4>
                    {mentorProfiles.map((profile) => (
                      profile.userId === mentor.uid && (
                        <div key={profile.userId}>
                          <br />
                          <p>{profile.profileDescription}</p>
                        </div>
                      )
                    ))}
                    <div className='mentor-calendly_container'>
                      {!currentUser ? (
                        <>
                          <Link to="/auth/signin">
                            <Button className='not_signMessage'>Message and Book A Meeting</Button>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Button onClick={() => openModal(mentor)}>Message</Button>
                          {mentor.calendlyLink && (
                            <a href={mentor.calendlyLink} target='_blank' className='calendlyLink' rel="noreferrer">
                              Book a meeting<i className="bi bi-bell-fill"></i>
                            </a>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Send a message to {selectedMentor?.displayName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="Type your message"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            className='mentor-input'
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>
            Close
          </Button>
          <Button onClick={handleSendMessage}>
            {sending && sendingMentor === selectedMentor ? 'Sending...' : 'Send'}
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default MentorsListing;
