import './team-members.css';
import '../daytopia.css';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from '../../../../Components/Sidebar/sidebar';
import '../../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../../Components/Header/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import Sarah from '../../../../assets/image/sarah.jpeg'
import '../../../../Components/Sidebar/sidebar.css'
import Breadcrumbs from './Breadcrumbs';

const TeamMember7 = () => {

    const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 
  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
    <Breadcrumbs />
    <section id="work-process" className="work-process">
    <div className="container">

        <div className="section-title" data-aos="fade-up">
            <h2>About Our Mentor Sarah Egbo</h2>
            <p className='founders-DP'></p>
        </div>

        <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
                <img src={ Sarah } className="img-fluid" alt="" />
            </div>
            <div className="col-md-7 pt-4" data-aos="fade-left">
               
                <p>
                Sarah Obaro Egbo is a passionate advocate for Gender and digital equality/ equity. She graduated with a first class honors degree in information resources management in 2016 from Babcock University, Ogun state
                </p>
                <p>
                Thereafter she served as a Youth corps member in Zamfara state & chaired an ICT oriented project, which earned her a state award by the Zamfara State government and NYSC for conducting the most impactful community Development project in the state of service in year 2017.
                </p>

                <p>
            This feat, along with her previous collaborations with NGOs such as SEVHAGE Literary and Development Initiative to promote positive community development, led her to attain one of the UK's prestigious award to pursue a Master's degree in the UK  in 2018. She's a 2018/2019 @cheveningfco @IDS_UK & @SussexUni Alumna. She's also an ICT4DEV Advocate and a Commonwealth Youth and Gender Equality Network Member
                </p>
              {/* <ul>
                    <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.
                    </li>
  </ul> */}
            </div>
        </div>


    </div>
</section>
<Footer />
    </div>
    </div>
  )
}

export default TeamMember7
