import { useEffect, useState } from "react";
import { db, auth } from "../../firebase";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";

const Profiledetails = () => {
  const [userData, setUserData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({
    courseAmbitions: "",
    university: "",
  });

  useEffect(() => {
    const user = auth.currentUser;

    if (user) {
      const userDocRef = doc(db, "users", user.uid);

      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          setUserData(doc.data());
          // Set edited data initially to current user data
          setEditedData({
            courseAmbitions: doc.data().courseAmbitions || "",
            university: doc.data().university || "",
          });
        }
      });

      return () => unsubscribe();
    }
  }, []);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const user = auth.currentUser;

      if (user) {
        const userDocRef = doc(db, "users", user.uid);

        // Update the user document in Firestore with the edited data
        await updateDoc(userDocRef, {
          courseAmbitions: editedData.courseAmbitions,
          university: editedData.university,
        });

        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  return (
    <div>
      <section className="profiledetails">
        <div className="container">
          <div className="profiledetailsection">
            
            <div className="profiledeatilscontainer">
              
              <div>
                <div className="profiledetailsflex">
                  <p>Career Specialism</p>
                  {isEditing ? (
                    <input
                      type="text"
                      name="courseAmbitions"
                      className="profile-detailsInput"
                      value={editedData.courseAmbitions}
                      placeholder="Banking"
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.courseAmbitions}</p>
                  )}
                </div>

                <div className="profiledetailsflex">
                  <p>Mentoring Interest</p>
                  {isEditing ? (
                    <input
                      type="text"
                      name="university"
                      className="profile-detailsInput"
                      placeholder="Personal Finance"
                      value={editedData.university}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <p>{userData.university}</p>
                  )}
                </div>
              </div>

              {/* Edit and Save buttons */}
              {isEditing ? (
                <button className="profile-detailsBtn" onClick={handleSaveChanges}>Save Changes</button>
              ) : (
                <button className="profile-detailsBtn" onClick={handleEditToggle}>Edit Profile</button>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profiledetails;
