import { useEffect, useState } from 'react';
import { db } from '../../firebase';
import {
  collection,
  getDocs
} from 'firebase/firestore';

const Test3 = () => {
  const [userCount, setUserCount] = useState(0);
  const [mentorCount, setMentorCount] = useState(0);
  const [menteeCount, setMenteeCount] = useState(0);
  

  useEffect(() => {
    const fetchData = async () => {
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);

      let totalUserCount = 0;
      let totalMentorCount = 0;
      let totalMenteeCount = 0;

      usersSnapshot.forEach((doc) => {
        const data = doc.data();
        totalUserCount++;

        if (data.role === 'mentor') {
          totalMentorCount++;
        } else if (data.role === 'mentee') {
          totalMenteeCount++;
        }
      });

      setUserCount(totalUserCount);
      setMentorCount(totalMentorCount);
      setMenteeCount(totalMenteeCount);
    };

    fetchData();
  }, []);

  return (
    <div>
      <section id="users" className="users section-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-6 d-flex align-items-stretch">
                  <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                    <div className="icon">
                      <i className="bi bi-people"></i>
                    </div>
                    <h4>Total Number of Users</h4>
                    <p>{userCount}</p>
                  </div>
                </div>

                <div className="col-md-6 d-flex align-items-stretch">
                  <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                    <div className="icon">
                      <i className="bi bi-people"></i>
                    </div>
                    <h4>Total Number of Mentors</h4>
                    <p>{mentorCount}</p>
                  </div>
                </div>

                <div className="col-md-6 d-flex align-items-stretch">
                  <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                    <div className="icon">
                      <i className="bi bi-people"></i>
                    </div>
                    <h4>Total Number of Mentees</h4>
                    <p>{menteeCount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Test3;
