import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import Sidebar from "../../../Components/Sidebar/sidebar";
import Navbar from '../../../Components/Header/Navbar';
import Breadcrumbs from "./Breadcrumbs";
import Footer from '../../../Components/Footer/Footer';
import Pagination from '../../../Components/pagination/Pagination'; // Import the Pagination component
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Sucessstories = () => {
  const [sucessStories, setSucessStories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true);
      } else {
        setUserIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    };
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "successStories"));
      const querySnapshot = await getDocs(q);
      const stories = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setSucessStories(stories);
    };

    fetchData();
  }, []);

  const postsPerPage = 8;
  const totalPosts = sucessStories.length;
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sucessStories.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className="d-flex">
      <div className={toggle ? "d-none" : "w-auto position-fixed"}>
        {userIsLoggedIn && <Sidebar />}
      </div>

      <div className={toggle ? "d-none" : "invisible"}>
        {userIsLoggedIn && <Sidebar />}
      </div>

      <div className='col'>
        <Navbar Toggle={Toggle}/>
        <Breadcrumbs />
        <section id="blog" className="blog">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <h1 className="blog-heading">Success Stories</h1>
            <div className="row gy-4 posts-list">
              {currentPosts.map((story) => (
                <div key={story.id} className="col-xl-4 col-md-6">
                  <div className="post-item position-relative h-100">
                    <div className="post-img position-relative overflow-hidden">
                      <img src={story.imageUrl} alt="Success Story Image" className="img-fluid" />
                      <span className="post-date">Success Stories</span>
                    </div>

                    <div className="blog-logo">
                      <h3>DT</h3>
                    </div>

                    <div className="post-content d-flex flex-column">
                      <Link to={`/journals/sucess-stories/sucesss-storiesdetails/${story.id}`}>
                        <h3 className="post-title">{story.title}</h3>
                      </Link>
                      <p>{story.content.slice(0, 300)}...</p>
                      <hr />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Sucessstories;
