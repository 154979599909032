import { useState } from "react";
import { auth } from "../../firebase";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './profile.css'

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState(""); // new state for old password
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)

  const handleChangePassword = async () => {
    try {
      const user = auth.currentUser;

      if (user) {
        // Ask for the old/current password
        const currentPassword = prompt("Please enter your current password:");
        const credential = EmailAuthProvider.credential(user.email, currentPassword);

        // Re-authenticate the user with the old password
        await reauthenticateWithCredential(user, credential);

        // Change the password
        await updatePassword(user, newPassword);

        // Clear the form fields
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setError(null);
      }
    } catch (error) {
      console.error("Error changing password:", error);
      setError(error.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const currentShowVisibility = () =>{
    setShowCurrentPassword(!showCurrentPassword)
  }

  return (
    <div className="container">
    <div className="changename">
      <h3>Change Password</h3>
      <div className="edit-profile-form">
        <div className="edit-name">
          <label htmlFor="oldPassword">Old Password:</label>
          <input
            type={showCurrentPassword ? "text" : "password"}
            name="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <span onClick={currentShowVisibility}>
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </span>
        </div>
        <div className="edit-name">
          <label htmlFor="newPassword">New Password:</label>
          <input
            type={showPassword ? "text" : "password"}
            name="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <span onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div className="edit-name">
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
          type={showPassword ? "text" : "password"}
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            
          />
          <span onClick={togglePasswordVisibility}>
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </span>
        </div>
        <button onClick={handleChangePassword}>Change Password</button>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
    </div>
  );
};

export default ChangePassword;
