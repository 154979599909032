import Setting from "./Setting"

const ProfileSetting = () => {
  return (
    <div>
        <Setting />
    </div>
  )
}

export default ProfileSetting