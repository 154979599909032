import React from 'react';
import './About.css'

const Introdction = () => {
  return (
    <div className='container'>
      <div className='content_inroduction'>
      <h3>Introduction to Daytopia Mentoring Network</h3>
      <p>It is no surprise that many young individuals struggle with some critical aspects of life.
       It has been seen so many times how young individuals feel lost in life and eventually fall
        off in their drive to succeed in Life. Daytopia Mentoring Network is the right place for
         you as a young aspiring individual who wants guidance from experts around the world or who just wants to be amidst highly motivated and self-driven network of other young individuals that are already conquering the world.</p> 
      </div>
    </div>
  )
}

export default Introdction