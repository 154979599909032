import { NavLink } from 'react-router-dom';

const signUpandSignInBtn = () => {
  return (
    <div>
      <div className='nav-buttons'>
        <NavLink to="/auth/signin" className="get-started-btn2 scrollto">Sign in</NavLink>
        <NavLink to="/auth/signup" className="get-started-btn scrollto">Sign up</NavLink>
      </div>
    </div>
  )
}

export default signUpandSignInBtn
