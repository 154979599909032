import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { getAuth, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { CSSTransition } from 'react-transition-group';
import { BeatLoader } from 'react-spinners';
import defaultImage from '../../assets/image/defaultImage.jpg';
import './profile.css';

const Profilesection = () => {
  const { currentUser, setUser } = useContext(AuthContext);
  const [newImage, setNewImage] = useState(null);
  const [photoURL, setPhotoURL] = useState(currentUser?.photoURL || '');
  const [isChangingImage, setIsChangingImage] = useState(false);

  // Define the default image URL
  const defaultImageUrl = '../../assets/image/defaultImage.jpg';

  // Function to handle image change
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setNewImage(file);

    try {
      setIsChangingImage(true);

      const storageRef = ref(getStorage(), `${currentUser.uid}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      await uploadTask;

      const downloadURL = await getDownloadURL(storageRef);

      // Update the user's profile with the new photoURL in Firebase Authentication
      const auth = getAuth();
      await updateProfile(auth.currentUser, { photoURL: downloadURL });

      // Update the user's profile in Firestore
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { photoURL: downloadURL });

      // Store the updated photoURL directly in your state
      setPhotoURL(downloadURL);

      // If you have a setUser function in your context, you can use it to refresh the user data
      setUser(await getAuth().currentUser);
    } catch (error) {
      console.error('Error updating profile image:', error);
    } finally {
      setIsChangingImage(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Check if currentUser is defined and not null
        if (currentUser && currentUser.uid) {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDocSnapshot = await getDoc(userDocRef);
  
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            if (userData.photoURL) {
              // If the user has a photoURL, set it
              setPhotoURL(userData.photoURL);
            } else {
              // If the user does not have a photoURL, set the default image URL
              setPhotoURL(defaultImageUrl);
            }
          } else {
            // If the user document doesn't exist, set the default image URL
            setPhotoURL(defaultImageUrl);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserData();
  }, [currentUser]);
  
  

  return (
    <div>
      <section className='profilesection'>
        <div className='container'>
          <div className='profilesectiondetails'>
            <CSSTransition
              in={isChangingImage}
              timeout={500}
              classNames="fade"
            >
              <div>
                <>
                  <img
                    src={photoURL}
                    alt='user profile image'
                    onClick={() => document.getElementById('fileInput').click()}
                    style={{ cursor: 'pointer' }}
                  />
                  <BeatLoader color="#123abc" loading={isChangingImage} />
                </>
              </div>
            </CSSTransition>
            {currentUser ? (
              <div className='UserRoleName'>
                <p>{currentUser.displayName}</p>
                <p className="profile_role">{currentUser.role}</p>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleImageChange}
                  id="fileInput"
                  style={{ display: 'none' }}
                />
              </div>
            ) : (
              <p></p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profilesection;
