import React from 'react';
import Spinner from '../../assets/svg/Spinner.svg';
import { useState, useEffect } from 'react';
import { auth, applyActionCode, checkActionCode } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VerifyPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { email } = useParams();

  useEffect(() => {
    const checkEmailVerification = async () => {
      try {
        const user = await auth.getUserByEmail(email);
        if (user.emailVerified) {
          toast.success('Email is already verified!');
          navigate('/');
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error checking email verification status:', error);
      }
    };

    checkEmailVerification();
  }, [email, navigate]);

  const handleVerifyCode = async () => {
    // Your verification code logic here
  };

  
  return (
        <div className="verify-page-container">
          <ToastContainer />
          {isLoading ? (
            <div className="loading-spinner">
              <Spinner />
              <p>Verifying...</p>
            </div>
          ) : null}
        </div>
      );
      
};

export default VerifyPage;
