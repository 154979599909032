import { createBrowserRouter } from "react-router-dom";
import About from "./Pages/About/About-us/About";
import Profile from "./Pages/Profile/profile";
import Home from "./Pages/Home/Home";
import Signin from "./Pages/SignIn/SignIn";
import Mentors from './Pages/MentorsListing/Mentors';
import Groups from './Pages/Groups/Groups';
import Signup from './Pages/SignUp/Signup';
import Contact from './Pages/About/Contact/Contact';
import FAQ from './Pages/About/FAQ/FAQ';
import CarrerAdvice from './Pages/Journals/CarrerAdvice/CarrerAdvice';
import News from './Pages/Journals/News/News';
import Sucessstories from './Pages/Journals/SuccessStories/Sucessstories';
import CodeOfConduct from './Pages/CodeOfConduct/CodeOfConduct'
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import Blog from './Pages/Journals/Blog/Blog';
import Jobs from './Pages/JobList/Jobs';
import Message from './Pages/MessageChats/HomeMessage';
import BlogDetails from './Pages/Journals/Blog/BlogDetails/BlogDetails';
import Admin from './Admin/UsersAdmin/Users';
import BlogAdmin from './Admin/Blog/Blog';
import JobAdmin from './Admin/Job/JobPosting';
import NotFoundPage from './Components/notfound/NotFound';
import Gallery from './Pages/About/Gallery/Galllery.jsx';
import NewsAdmin from './Admin/News/News';
import BooksAdmin from './Admin/Books/Books';
import NewsLetterAdmin from './Admin/NewsLetter/NewsLetterAdmin';
import GalleryAdmin from './Admin/Gallery/Gallery';
import DaytopiaFoundation from './Pages/About/DaytopiaFoundations/daytopiafoundation';
import ForgottenPassword from './Pages/ForgottenPassword/ForgoteenPassword'
import SucessStories from './Admin/SucessStories/SucessStories';
import SignupAdmin from './Admin/auth/Signup';
import SucessStoriesDetails from './Pages/Journals/SuccessStories/SucessStoriesDetails.jsx/SucessStoriesDetails';
import NewsDetails from './Pages/Journals/News/News/NewsDetails';
import BooksandPublications from './Pages/DaytopiaShop/Books&Publications/BooksandPublications';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import './Components/General.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import UserAuth from "./Admin/users_auth/UserAuth";
import TeamMember1 from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/TeamMember1";
import TeamMember9 from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/TeamMember9";
import TeamMember7 from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/TeamMember7";
import TeamMember8 from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/TeamMember8";
import TeamMember6 from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/TeamMember6";
import TeamMember5 from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/TeamMember5";
import TeamMember4 from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/TeamMember4";
import TeamMember2 from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/TeamMenber3";
import TeamMenber3 from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/TeamMember2";
import VerifyPage from "./Pages/SignUp/VerifyPage";
import NoAccess from "./Components/notfound/NoAccess";
import EmailVerificationBanner from "./Components/verification/Verificationbanner";
import MenteeData from "./Admin/menteeData/MenteeData";
import DaytopiaInvestmentClub from "./Pages/DaytopiaShop/daytopia-investmentclub/DaytopiaInvestmentClub";
import Carrer from "./Pages/DaytopiaShop/Carrer/Carrer";
import MentorData from "./Admin/mentorData/MentorData.jsx";
import CarrerPost from "./Admin/carrerPost/CarrerPost.jsx";
import MentorSolomon from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/MentorSolomon.jsx";
import MentorAbdkulkdi from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/MentorAbdkulkdi.jsx";
import MentorLukman from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/MentorLukman.jsx";
import MentorBabatunde from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/MentorBabatunde.jsx";
import MentorOluwafemi from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/MentorOluwafemi.jsx";
import MentorAfolabi from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/MentorAfolabi.jsx";
import MentorMariam from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/MentorMariam.jsx";
import MentorBlessing from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/MentorBlessing.jsx";
import MentorAdetayo from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/MentorAdetayo.jsx";
import MentorMusa from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/MentorMusa.jsx";
import MentorPhillomina from "./Pages/About/DaytopiaFoundations/team-menbers.jsx/MentorPhillomina.jsx";
import ProfileSetting from "./Pages/profileSetting/ProfileSetting.jsx";


export const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: '/',
      element: <EmailVerificationBanner />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/profile/settings",
      element: <ProfileSetting />,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/auth/signin",
      element: <Signin />,
    },
    {
      path: "/mentors",
      element: <Mentors />,
    },
    {
      path: "/groups",
      element: <Groups />,
    },
    {
      path: "/about/contact",
      element: <Contact />,
    },
    {
      path: "/admin/mentee-data",
       element: <MenteeData />,
    },
    {
      path: '/admin/mentor-data',
      element: <MentorData />,
    },
    {
      path: "/auth/signup",
      element: <Signup />,
    },
    {
      path: "/about/faq",
      element: <FAQ />,
    },
    {
      path: "/journals/carrerAdvice",
      element: <CarrerAdvice />,
    },
    {
      path: "/journals/news",
      element: <News />,
    },
    {
      path: "/journals/sucessstories",
      element: <Sucessstories />,
    },
    {
      path: "/code-of-conduct",
      element: <CodeOfConduct />,
    },
    {
      path: "/privacypolicy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/journals/Blog",
      element: <Blog />,
    },
    {
      path: "/jobs",
      element: <Jobs />,
    },
    {
      path: "/message",
      element: <Message />,
    },
    {
      path: "/journals/blog/blogdetails/:id",
      element: <BlogDetails />,
    },
    {
      path: "/admin",
      element: <Admin />,
    },
    {
      path: "/admin/blog",
      element: <BlogAdmin />,
    },
    {
      path: "/daytopia-shop/carrer",
      element: <Carrer />,
    },
    {
      path: '/admin/carrer-post',
      element: <CarrerPost />,
    },
    {
      path: "/admin/job",
      element: <JobAdmin />,
    },
    {
      path: "/about/gallery",
      element: <Gallery />,
    },
    {
      path: "/admin/news",
      element: <NewsAdmin />,
    },
    {
      path: "/admin/books",
      element: <BooksAdmin />,
    },
    {
      path: "/admin/newsletter",
      element: <NewsLetterAdmin />,
    },
    {
      path: "/admin/gallery",
      element: <GalleryAdmin />,
    },
    {
      path: "/about/daytopiafoundation",
      element: <DaytopiaFoundation />,
    },
    {
      path: "/admin/sucessstories",
      element: <SucessStories />,
    },
    {
      path: "/journals/news/newsdetails/:id",
      element: <NewsDetails />,
    },
    {
      path: "/admin/auth/signup",
      element: <SignupAdmin />,
    },
    {
      path: "/verify-email/:email",
      element: <VerifyPage />,
    },
    {
      path: '/no-access',
      element: <NoAccess />
    },
    {
      path: "/journals/sucess-stories/sucesss-storiesdetails/:id",
      element: <SucessStoriesDetails />,
    },
    {
      path: "/daytopia-shop/booksandpublications",
      element: <BooksandPublications />,
    },
    {
      path: "/auth/forgottenpassword",
      element: <ForgottenPassword />,
    },
    {
      path: "/*",
      element: <NotFoundPage />,
    },
    {
      path: 'daytopia-shop/daytopia-investment-club',
      element: <DaytopiaInvestmentClub />,
    },
    {
      path: "/admin/auth/create-mentor",
      element: <UserAuth />
    },
    {
      path: "/about/daytopiafoundation/Akay Obekpa",
      element: <TeamMember1 />
    },
    {
      path: "/about/daytopiafoundation/Subomi David",
      element: <TeamMenber3 />
    },
    {
      path: "/about/daytopiafoundation/Deborah Braide",
      element: <TeamMember2 />
    },
    {
      path: "/about/daytopiafoundation/Fatimah Bamisedun",
      element: <TeamMember4 />
    },
    {
      path: "/about/daytopiafoundation/Kolawole Seyi",
      element: <TeamMember5 />
    },
    {
      path: "/about/daytopiafoundation/Giwa Ridwan",
      element: <TeamMember6 />,
    },
    {
      path: "/about/daytopiafoundation/Sarah Egbo",
      element: <TeamMember7 />,
    },
    {
      path: "/about/daytopiafoundation/Dr. Adejumoke Awosanya (Co-Founder)",
      element: <TeamMember8 />,
    },
    {
      path: "/about/daytopiafoundation/Janet Temlari",
      element: <TeamMember9 />,
    },
    {
      path: "/about/daytopiafoundation/Mentor Solomon",
      element: <MentorSolomon />,
    },
    {
      path: "/about/daytopiafoundation/Mentor Abdulkhaid",
      element: <MentorAbdkulkdi />,
    },
    {
      path: "/about/daytopiafoundation/Mentor Lukman",
      element: <MentorLukman />,
    },
    {
      path: "/about/daytopiafoundation/Mentor Babatunde",
      element: <MentorBabatunde />,
    },
    {
      path: "/about/daytopiafoundation/Mentor Oluwafemi",
      element: <MentorOluwafemi />,
    },
    {
      path: "/about/daytopiafoundation/Mentor Afolabi",
      element: <MentorAfolabi />,
    },
    {
      path: "/about/daytopiafoundation/Mentor Mariam",
      element: <MentorMariam />,
    },
    {
      path: "/about/daytopiafoundation/Mentor Blessing",
      element: <MentorBlessing />,
    },
    {
      path: "/about/daytopiafoundation/Mentor Adetayo",
      element: <MentorAdetayo />,
    },
    {
      path: "/about/daytopiafoundation/Mentor Musa",
      element: <MentorMusa />,
    },
    {
      path: "/about/daytopiafoundation/Mentor Phillomina",
      element: <MentorPhillomina />,
    },
   
  ]);
