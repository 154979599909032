import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from "../../../../Components/Sidebar/sidebar";
import '../../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../../Components/Header/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import Breadcrumbs from "./Breadcrumbs";
import Recent from "../../../../Components/sidebarRecent/Recent";

const SuccessStories = () => {
  const [successStory, setSuccessStory] = useState(null); // Change to single story state
  const { id } = useParams();
  const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 

  useEffect(() => {
    // Fetch success stories from Firestore for the frontend success stories page
    const fetchData = async () => {
      const q = query(collection(db, "successStories"));
      const querySnapshot = await getDocs(q);
      const stories = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (doc.id === id) {
          stories.push({ ...data, id: doc.id });
        }
      });
      if (stories.length > 0) {
        setSuccessStory(stories[0]);
      }
    };

    fetchData();
  }, [id]);

  if (!successStory) {
    // Handle the case where the success story is not found or not loaded yet
    return <div>Loading...</div>;
  }

  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
    <Breadcrumbs />
    <section id="success-stories" className="success-stories">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-8">
            <article className="article" key={successStory.id}>
              <div className="post-img">
                <img src={successStory.imageUrl} alt="" className="img-fluid" />
              </div>
              <h2 className="title">{successStory.title}</h2>
              <div className="content sucesstories-conent">
                <p>{successStory.content}</p>
                <div
                className="content sucesstories-conent"
                dangerouslySetInnerHTML={{ __html: successStory.content }}
              />
              </div>
            </article>
          </div>
          <Recent />
        </div>
      </div>
    </section>
    <Footer />
    </div>
    </div>
  );
};

export default SuccessStories;
