import { useState, useEffect, useContext } from "react";
import { collection, query, getDocs, addDoc, deleteDoc, doc, Timestamp } from "firebase/firestore";
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import Sidebar from "../sidebar";
import Navbar from "../Navbar";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; // Replace with the actual path to AuthContext
import '../admin.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AdminNews = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [newsPosts, setNewsPosts] = useState([]);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // Check the user's role and navigate to the home page if they are not an admin
    if (!currentUser || currentUser.role !== "admin") {
      navigate('/no-access');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    // Fetch news posts from Firestore for the admin panel
    const fetchData = async () => {
      const q = query(collection(db, "newsPosts"));
      const querySnapshot = await getDocs(q);
      const posts = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id; // Store the document ID for deleting
        posts.push(data);
      });
      setNewsPosts(posts);
    };

    fetchData();
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleCreateNews = async () => {
    if (!title || !content || !image) {
      alert("Please fill in all fields.");
      return;
    }

    // Upload the image to Firebase Storage
    const storageRef = ref(storage, `images/${image.name}`);
    await uploadBytes(storageRef, image);

    // Get the image URL from Firebase Storage
    const imageUrl = await getDownloadURL(storageRef);

    // Create a new news post in Firestore
    const newNewsRef = await addDoc(collection(db, "newsPosts"), {
      title,
      content,
      imageUrl,
      timestamp: Timestamp.now(),
    });

    // Add the new post to the displayed list
    setNewsPosts([...newsPosts, { title, content, imageUrl, id: newNewsRef.id }]);

    setTitle("");
    setContent("");
    setImage(null);
  };

  const handleDeleteNews = async (newsId) => {
    try {
      await deleteDoc(doc(db, "newsPosts", newsId));
      const updatedNews = newsPosts.filter((post) => post.id !== newsId);
      setNewsPosts(updatedNews);
    } catch (error) {
      console.error("Error deleting news post:", error);
    }
  };

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    }
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }

  }, []);

  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    <Sidebar />
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    <Sidebar />
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
     <div className="admin">
      <h1> News <span className="admintitle">Panel</span></h1>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <ReactQuill
      theme="snow"
      value={content}
      onChange={(value) => setContent(value)}
      placeholder="Content"
    />
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        onChange={handleImageUpload}
      />
      <button onClick={handleCreateNews}>Create News</button>

      <h2>News <span className="admintitle">Posts</span></h2>
      <ul>
        {newsPosts.map((post) => (
          <li key={post.id}>
            <p>{post.title}</p>
            <button onClick={() => handleDeleteNews(post.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
    </div>
    </div>
  );
};

export default AdminNews;
