import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import Sidebar from "../../../Components/Sidebar/sidebar";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../Components/Header/Navbar';
import Footer from '../../../Components/Footer/Footer';
import Breadcrumbs from "./Breadcrumbs";
import Pagination from '../../../Components/pagination/Pagination'; 

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true);
      } else {
        setUserIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    };
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "blogPosts"));
      const querySnapshot = await getDocs(q);
      const posts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setBlogPosts(posts);
    };

    fetchData();
  }, []);

  const postsPerPage = 8;
  const totalPosts = blogPosts.length;
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className="d-flex">
      <div className={toggle ? "d-none" : "w-auto position-fixed"}>
        {userIsLoggedIn && <Sidebar />}
      </div>

      <div className={toggle ? "d-none" : "invisible"}>
        {userIsLoggedIn && <Sidebar />}
      </div>

      <div className='col'>
        <Navbar Toggle={Toggle}/>
        <Breadcrumbs />
        <section id="blog" className="blog">
          <div className="container" data-aos="fade-up">
            <h1 className="blog-heading">Blog</h1>
            <div className="row">
              <div className="col-lg-8 entries">
                {currentPosts.map((post) => (
                  <article key={post.id} className="entry">
                    <div className="entry-img">
                      <img src={post.imageUrl} alt="" className="img-fluid" />
                    </div>
                    <h1 className="blog_title entry-title">
                      <Link to={`/journals/blog/blogdetails/${post.id}`}>{post.title}</Link>
                    </h1>
                    <div className="entry-meta">
                      <ul>
                        <li className="d-flex align-items-center">
                          <i className="bi bi-person"></i> By Daytopia
                        </li>
                      </ul>
                    </div>
                    <div className="entry-content">
                    <div
                    className="content blog-content"
                    dangerouslySetInnerHTML={{ __html: post.content.slice(0, 300) }}
                  />

                  
                      <div className="read-more">
                        <Link to={`/journals/blog/blogdetails/${post.id}`}>Read More</Link>
                      </div>
                    </div>
                  </article>
                ))}
               
              </div>
            </div>

            <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Blog;
