import './team-members.css';
import Abdulkhalid from '../../../../assets/image/Abdulkhalid.jpeg';
import '../daytopia.css';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from '../../../../Components/Sidebar/sidebar';
import '../../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../../Components/Header/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import '../../../../Components/Sidebar/sidebar.css';
import Breadcrumbs from './Breadcrumbs';

const MentorAbdkulkdi = () => {
    const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 
  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
    <Breadcrumbs />
    <section id="work-process" className="work-process">
    <div className="container">

        <div className="section-title" data-aos="fade-up">
            <h2>About Our Mentor Abdulkhalid</h2>
            <p className='founders-DP'></p>
        </div>

        <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
                <img src={ Abdulkhalid } className="img-fluid" alt="" />
            </div>
            <div className="col-md-7 pt-4" data-aos="fade-left">
               
                <p>
                Abdulkhalid is deeply passionate about fostering economic development in the global south. With a background in economics, strong analytical skills, and research experience, he is committed to addressing complex economic issues through evidence-based research and effective communication for policymaking. His recent work as a researcher at the Centre for the Study of the Economies of Africa (CSEA) demonstrate his focus on addressing educational inequities. Beyond research, Khalid excels in collaborative environments, having co-founded The Investment Society at Ahmadu Bello University and worked effectively in multicultural teams such as Strategy Connect UAE.
                </p>
                <p>
                Motivated by a thirst for knowledge and a desire to make a positive impact, he is well-positioned for engagements in the development space. Khalid's dedication, analytical prowess, and leadership qualities make him an asset to any organization aiming to tackle intricate economic challenges and drive transformative change.
                </p>
              {/* <ul>
                    <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.
                    </li>
  </ul> */}
            </div>
        </div>


    </div>
</section>
<Footer />
    </div>
    </div>
  )
}

export default MentorAbdkulkdi
