import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();
  const [buttonText, setButtonText] = useState('Send Message');

  const sendEmail = (e) => {
    e.preventDefault();

    // Change the button text to "Sending..."
    setButtonText('Sending...');

    emailjs.sendForm('service_l63jszq', 'template_3ps8rzj', form.current, 'mOnPJs2SL-vGe3WDS') 
      .then((result) => {
        console.log(result.text);
        // Clear the form fields after a successful submission
        form.current.reset();
        // Change the button text to "Sent"
        setButtonText('Sent');
        // Reset the button text to "Send Message" after a delay (e.g., 2 seconds)
        setTimeout(() => setButtonText('Send Message'), 2000);
      })
      .catch((error) => {
        console.log(error.text);
        // Change the button text back to "Send Message" in case of an error
        setButtonText('Send Message');
      });
  };

  return (
    <main id='main'>
    <section id="contact" className="contact">
    <div className="container" data-aos="fade-up">

        <div className="section-title">
            <h2>Contact</h2>
            <p>Do you have any questions for Daytopia Mentoring Network kindly reach out</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">

            <div className="col-lg-6">

                <div className="row">
                    <div className="col-md-12">
                        <div className="info-box">
                        <i className="bi bi-geo-alt"></i>
                            <h3>Our Address</h3>
                            <p>Lagos, Nigeria and Manchester, United Kingdom</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="info-box mt-4">
                        <i className="bi bi-telephone"></i>
                            <h3>Email Us</h3>
                            <p>daytopia@gmail.com<br />admin@daytopiagroup.com</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="info-box mt-4">
                        <i className="bi bi-envelope"></i>
                            <h3>Call Us</h3>
                            <p>+44 7884 274 271 </p>
                            <p>+234 908 219 1025</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="col-lg-6">
                <form className="php-email-form" ref={form} onSubmit={sendEmail}>
                    <div className="row">
                        <div className="col form-group">
                            <input type="text" name="name" className="form-control" id="name"
                                placeholder="Your Name" required />
                        </div>
                        <div className="col form-group">
                            <input type="email" className="form-control" name="email" id="email"
                                placeholder="Your Email" required />
                        </div>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" name="subject" id="subject"
                            placeholder="Subject" required />
                    </div>
                    <div className="form-group">
                        <textarea className="form-control" name="message" rows="5" placeholder="Message"
                            required></textarea>
                    </div>
                    <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center">
                    <button type="submit" value="send">{buttonText}</button>
                    </div>
                </form>
            </div>

        </div>

    </div>
</section>
    </main>
  )
}

export default ContactForm
