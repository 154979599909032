import './team-members.css';
import '../daytopia.css';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from '../../../../Components/Sidebar/sidebar';
import '../../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../../Components/Header/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import '../../../../Components/Sidebar/sidebar.css';
import Fathimoh from '../../../../assets/image/Miss Fatimah Bamisedun.jpeg';
import Breadcrumbs from './Breadcrumbs';

const TeamMember4 = () => {
    const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 
  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
    <Breadcrumbs />
    <section id="work-process" className="work-process">
    <div className="container">

        <div className="section-title" data-aos="fade-up">
            <h2>About Our Mentor Fatimah Bamisedun</h2>
            <p className='founders-DP'></p>
        </div>

        <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
                <img src={ Fathimoh } className="img-fluid" alt="Fathimoh Bamisedun" />
            </div>
            <div className="col-md-7 pt-4" data-aos="fade-left">
               
                <p>
                Fatimah Bamisedun is an ecosystem catalyst and strategic utility player. Fatimah has garnered over 7+ years of vast experience in corporate and operations strategy, organisation development and IT enablement. An ex-KPMG consultant, her experience spans across various sectors in the energy, consumer markets and not-for-profit sectors. She worked on a major transformation project that positioned an indigenous business compete with global brands for market share. She was selected as a KPMG Courageous Champion to foster collaboration and an inclusive workplace. 
                </p>
                <p>
                KPMG Fatimah is the Vice Curator of the World Economic Forum, Global Shapers Community, Lagos Hub. She was elected to the position in March 2021 and sworn into the office in July 2021. She leads the Lagos charter of a global network of young and inspiring individuals, successful in their chosen careers and businesses, contributing to impact projects that directly improve their community. 
                </p>
                <p>
                She is a polymath, philanthropist and entrepreneur. She sits on the advisory board of two companies, an NGO and UK-based start-up. She is a published author of the book, Morsels of Stardust. A recipient of the Funto Babatola Mentoring Award, for her contributions to mentoring the girl child. She also presented an award to the former governor of Lagos State, Babatunde R. Fashola, at the Youth Stakeholders Forum, as one of the Best Graduating Students of Ignite Employability and Enterprise Project. 
                </p>
                <p>
                She is the founder of Ultra Life Boost, a consultancy focused on helping young individuals reach their potential and tackle unplanned, “quarter-life crisis”, towards social fulfilment and prosperity at retirement.
                </p>
              {/* <ul>
                    <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.
                    </li>
  </ul> */}
            </div>
        </div>


    </div>
</section>
<Footer />
    </div>
    </div>
  )
}

export default TeamMember4
