import './team-members.css';
import '../daytopia.css';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from '../../../../Components/Sidebar/sidebar';
import '../../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../../Components/Header/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import Miariam  from '../../../../assets/image/Miariam.jpeg';
import '../../../../Components/Sidebar/sidebar.css';
import Breadcrumbs from './Breadcrumbs';

const MentorMariam = () => {

   const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 
  return (
    <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
    <Breadcrumbs />
    <section id="work-process" className="work-process">
    <div className="container">

        <div className="section-title" data-aos="fade-up">
            <h2>About Our Mentor Dr.Miariam Akinlolu  </h2>
            <p className='founders-DP'></p>
        </div>

        <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
                <img src={ Miariam  } className="img-fluid" alt="" />
            </div>
            <div className="col-md-7 pt-4" data-aos="fade-left">
               
                <p>
                Miariam Akinlolu is a Lecturer in Construction and the Head of Research and Knowledge Exchange at the School of Built Environment at London Metropolitan University. She is also a Research Fellow at Nelson Mandela University. Port Elizabeth, South Africa.  She obtained her BTech. in Project Management from Bells University of Technology and a Master of Construction from the Cape Peninsula University of Technology, South Africa. She holds a PhD in Construction Management from the University of KwaZulu-Natal, South Africa. 
                </p>
                <p>
                Mariam has presented papers at conferences locally and abroad, published in peer-reviewed journals; written chapters in books, and edited several book collections. She has been involved in internationally funded projects in the areas of sustainable built environment education, waste management, construction health and safety, and circular economy. Her research interests are engineering education, EDI issues in construction, sustainability, and construction health and safety.
                </p>

                <a href='https://outlook.office.com/bookwithme/user/95266848922c4c8796955065a1a4ce01%40londonmet.ac.uk/meetingtype/09705449-c7ee-4131-b1b9-3e961b18baf2?anonymous' target='_blank' rel="noreferrer">Check out</a>
               
              {/* <ul>
                    <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.
                    </li>
  </ul> */}
            </div>
        </div>


    </div>
</section>
<Footer />
    </div>
    </div>
  )
}

export default MentorMariam
