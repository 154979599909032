/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import elizabeth from '../../../assets/image/about-7.webp';
import GiwaRidwan from '../../../assets/image/Giwa Ridwan.jpg';
import AdejumokeAwonsa from '../../../assets/image/Dr. Adejumoke Awosanya.jpg';
import Sumbobi from '../../../assets/image/Mr. Subomi David.jpeg';
import Temmari from '../../../assets/image/Miss Temlari Janet Gapsiso.jpg';
import Fathimoh from '../../../assets/image/Miss Fatimah Bamisedun.jpeg';
import DeborahBraid from '../../../assets/image/Deborah Braide .jpeg';
import Habillihua from '../../../assets/image/Habibullah Abdulrazak.jpg';
import { Link } from 'react-router-dom';

const TeamCarousel = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 968);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };
  
  return (
    <div>
    <section id="testimonials" className="testimonials section-bg">
    <div className="container">
      <div className="section-title" data-aos="fade-up">
        <h2>Meet the Daytopia Mentors</h2>
      </div>
     
      <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
        <div className="swiper-wrapper">
       
        <Slider {...settings}>
          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ GiwaRidwan } className="testimonial-img" alt="" />
                <h3>Giwa Ridwan</h3>
                <h4>Chartered Accountant</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Ridwan Giwa is an accounting and finance professional with 7 years experience that spans across the oil and gas servicing and financial services industry.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                   <Link to='/about/daytopiafoundation/Giwa Ridwan'>Read More...</Link>
                </p>
                <a href='https://www.linkedin.com/in/ridwan-giwa-aca-dip-ifr-acca-fmva%C2%AE-prince2-62839a8b/ ' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ AdejumokeAwonsa } className="testimonial-img" alt="Dr. Adejumoke Awosanya" />
                <h3>Dr. Adejumoke Awosanya (Co-Founder)</h3>
                <h4>Co-Founder of Daytopia</h4>
                <a href='https://www.linkedin.com/in/habibullahiabdulrazak/' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

        
          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Sumbobi } className="testimonial-img" alt="Subomi David" />
                <h3>Subomi David</h3>
                <h4>Subomi David and Data Analyst</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Subomi is an experienced support engineer with wide range experience in data analytics, network security, Project management, implementation, Azure identity support. 
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Subomi David'>Read More</Link>
                </p>
                <a href='https://www.linkedin.com/in/subbydav/' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
          
         

         
          </Slider>

        </div>
        <div className="swiper-pagination"></div>
      </div>

    </div>
  </section>
    </div>
  );
}

export default TeamCarousel
