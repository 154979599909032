

const Breadcrumbs = () => {
  return (
    <div>
    <main id='main'>
    <div className="breadcrumbs d-flex align-items-center breadcrumbs-about">
      <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        <h2 className='breadcrubms-text'>Find Your Mentors</h2>
      </div>
    </div>

    </main>
    </div>
  )
}

export default Breadcrumbs
