import React, { useState, useEffect, useContext } from "react";
import {
  collection,
  addDoc,
  deleteDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { db } from "../../firebase";
import Sidebar from "../sidebar";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import "../admin.css";
import { AuthContext } from "../../context/AuthContext";

const JobManager = () => {
  const [companyImage, setCompanyImage] = useState("");
  const [location, setLocation] = useState("");
  const [jobName, setJobName] = useState("");
  const [skills, setSkills] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [jobTypeFilter, setJobTypeFilter] = useState("");
  const [jobDuration, setJobDuration] = useState("");
  const [amount, setAmount] = useState("");
  const [applyUrl, setApplyUrl] = useState("");
  const [jobListings, setJobListings] = useState([]);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser || currentUser.role !== "admin") {
      navigate("/no-access");
    }
  }, [currentUser, navigate]);

  const fetchJobListings = async () => {
    const jobListingsCollection = collection(db, "jobListings");
    const querySnapshot = await getDocs(jobListingsCollection);
    const jobListingsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setJobListings(jobListingsData);
  };

  const handleImageUpload = (e) => setCompanyImage(e.target.files[0]);

  const handleCreateJob = async () => {
    if (
      !companyImage ||
      !location ||
      !jobName ||
      !skills ||
      !jobTypeFilter ||
      !jobDuration ||
      !amount
    ) {
      alert("Please fill in all fields.");
      return;
    }

    const storage = getStorage();
    const storageRef = ref(storage, `images/${companyImage.name}`);
    await uploadBytes(storageRef, companyImage);
    const imageUrl = await getDownloadURL(storageRef);

    const jobListingData = {
      companyImage: imageUrl,
      location,
      jobName,
      skills,
      jobDescription, // Include jobDescription in jobListingData
      jobType: jobTypeFilter,
      jobDuration,
      amount,
      applyUrl,
    };

    const jobListingsCollection = collection(db, "jobListings");
    await addDoc(jobListingsCollection, jobListingData);

    // Reset form fields
    setCompanyImage("");
    setLocation("");
    setJobName("");
    setSkills("");
    setJobDescription(""); // Reset jobDescription
    setJobTypeFilter("");
    setJobDuration("");
    setAmount("");
    setApplyUrl("");

    fetchJobListings();
  };

  const deleteJobPosting = async (jobId, jobName) => {
    const jobListingDoc = doc(db, "jobListings", jobId);
    await deleteDoc(jobListingDoc);
    fetchJobListings();
    alert(`Deleted job listing: ${jobName}`);
  };

  useEffect(() => {
    fetchJobListings();
  }, []);

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    };
    window.addEventListener("resize", handleSize);

    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  return (
    <div className="d-flex">
      {/* Sidebar component */}
      <div className={toggle ? "d-none" : "w-auto position-fixed"}>
        <Sidebar />
      </div>

      {/* Invisible sidebar for smaller screens */}
      <div className={toggle ? "d-none" : "invisible"}>
        <Sidebar />
      </div>

      {/* Main content */}
      <div className="col">
        {/* Navbar component */}
        <Navbar Toggle={Toggle} />
        <div className="admin">
          <h1>
            Job <span className="admintitle">Manager</span>
          </h1>
          {/* Form for creating job listings */}
          <form>
            {/* File input for company logo */}
            <div>
              <label>Job Company Logo</label>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={handleImageUpload}
              />
            </div>
            {/* Input fields for job details */}
            <input
              type="text"
              placeholder="Job Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            <input
              type="text"
              placeholder="Job Name"
              value={jobName}
              onChange={(e) => setJobName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Job Skills (comma-separated)"
              value={skills}
              onChange={(e) => setSkills(e.target.value)}
            />
            {/* Job description input field */}
            <div>
              <label>Job Description</label>
              <input
                type="text"
                placeholder="Job Description"
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
              />
            </div>
            {/* Job type filter dropdown */}
            <div className="filteJobr_admin">
              <label>Job Type Filter</label>
              <select
                value={jobTypeFilter}
                onChange={(e) => setJobTypeFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="Finance">Finance</option>
                <option value="Software Development">Software Development</option>
                <option value="Teaching">Teaching</option>
                <option value="Healthcare">Healthcare</option>
                {/* Add other options as needed */}
              </select>
            </div>
            {/* Remaining input fields for job details */}
            <input
              type="text"
              placeholder="Job Duration (month, week, hr)"
              value={jobDuration}
              onChange={(e) => setJobDuration(e.target.value)}
            />
            <input
              type="text"
              placeholder="Job Amount or Salary"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <input
              type="text"
              placeholder="Job Apply Now URL"
              value={applyUrl}
              onChange={(e) => setApplyUrl(e.target.value)}
            />
            {/* Create job listing button */}
            <button type="button" onClick={handleCreateJob}>
              Create Job Listing
            </button>
          </form>

          {/* Display existing job listings */}
          {jobListings.map((job) => (
            <div key={job.id}>
              <img src={job.companyImage} alt="Company Logo" />
              <p className="jobfetchs">Location: {job.location}</p>
              <p>Job Name: {job.jobName}</p>
              <p className="jobfetchs">Skills: {job.skills}</p>
              <p className="jobfetchs">Job Type: {job.jobType}</p>
              <p className="jobfetchs">Job Duration: {job.jobDuration}</p>
              <p className="jobfetchs">Amount: {job.amount}</p>
              <p className="jobfetchs">Apply Now URL: {job.applyUrl}</p>
              <button onClick={() => deleteJobPosting(job.id, job.jobName)}>
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobManager;
