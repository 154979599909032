import { useState, useEffect, useContext } from "react";
import { NavLink } from 'react-router-dom';
import '../../Components/General.css';
import './Header.css';
import '../../Admin/admin.css';
import openIcon from '../../assets/icons/icons8-menu-24.png';
import closeIcon from '../../assets/icons/icons8-close-24.png';
import SignUpandSignInBtn from "../../Components/navchecker/signUpandSignInBtn";
import LoginBtn from "../../Components/navchecker/loginBtn";
import Daytopia from '../../assets/image/Datopia.jpeg';
import { AuthContext } from "../../context/AuthContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Navbar = ({ Toggle }) => {
  const [active, setActive] = useState("nav__menu");
  const [toggleIcon, setToggleIcon] = useState('nav__toggler');
  const { currentUser } = useContext(AuthContext);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);

  const auth= getAuth();

  useEffect(()=>{
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if(user){
          setUserIsLoggedIn(true);
        }else{
          setUserIsLoggedIn(false);
        }
      });

      return() => unsubscribe();
  }, [auth])

  const navToggle = () => {
    setActive(active === "nav__menu" ? "nav__menu nav__active" : "nav__menu");

    // ToggleIcon
    setToggleIcon(toggleIcon === 'nav__toggler' ? 'nav__toggler toggle' : 'nav__toggler');
  };

  return (
    <header id='header' className="fixed-top navbar-expand-lg d-flex align-items-center">
    <div className="container d-flex align-items-center">
    <div className='me-auto logo-menuflex'>
    {currentUser, userIsLoggedIn && (
      <a className="navbar-brand sidebarclose d-block " onClick={Toggle}><i className="bi bi-justify"></i></a>
    )}
     <NavLink to="/"><img src={ Daytopia } className="logoimg" alt="Daytopia logo"/></NavLink>
    </div>
    <nav id="navbar" className="navbar order-last order-md-0">
    <ul className={active}>
    <li>
    <NavLink className="nav-link" to="/">Home</NavLink>
    </li>

    <li>
    <NavLink className="nav-link " to="/mentors">Mentors</NavLink>
    </li>

    <li>
    <NavLink className="nav-link " to="/jobs">Jobs</NavLink>
    </li>

    <li className="dropdown"><NavLink to="/journals/sucessstories"><span>Journals</span> <i className="bi bi-chevron-down"></i></NavLink>
        <ul>
            <li>
            <NavLink to="/journals/sucessstories">Sucess Stories</NavLink>
            </li>
            <li>
            <NavLink to="/journals/news">News</NavLink>
            </li>
            <li>
            <NavLink to="/journals/blog">Blog </NavLink>
            </li>
            <li>
            <NavLink to="/Admin">Admin</NavLink>
            </li>
        </ul>
    </li>

    <li className="dropdown"><NavLink to="/about"><span>About</span><i className="bi bi-chevron-down"></i></NavLink>
        <ul>
                    <li>
                    <NavLink to="/about">About us</NavLink>
                    </li>
                    <li>
                    <NavLink to="/about/daytopiafoundation">Daytopia Foundation</NavLink>
                    </li>
         {/*     <li>
             <NavLink to="/Partners">Partners</NavLink>
             </li>*/}
             {/* <li>
             <NavLink to="/Events">Events</NavLink>
             </li>*/}
             <li>
             <NavLink to="/about/gallery">Gallery</NavLink>
             </li>
         <li>
         <NavLink to="/about/contact">Contact</NavLink>
         </li>
         <li>
         <NavLink to="/about/faq">FAQ</NavLink>
         </li>
        </ul>
    </li> 

    <li className="dropdown"><NavLink to="/daytopia-shop/booksandpublications"><span>Shop Daytopia</span> <i className="bi bi-chevron-down"></i></NavLink>
        <ul>
            <li><NavLink to="/daytopia-shop/carrer">Our Career</NavLink></li>
            <li><NavLink to="/daytopia-shop/daytopia-investment-club">Daytopia Investment Club</NavLink></li>
            <li><NavLink to="/daytopia-shop/booksandpublications">Book and Publications</NavLink></li>
        </ul>
    </li>
    
    </ul>
    <div onClick={navToggle} className={toggleIcon}>
    <img src={toggleIcon === 'nav__toggler' ? openIcon : closeIcon} alt="Toggle Icon" />
    </div>
    </nav>
    <div>
    {/* Conditionally render login or sign up / sign in based on user authentication status */}
    <div>
    {currentUser && currentUser.uid ? (
      <LoginBtn />
    ) : (
      <SignUpandSignInBtn />
    )}
  </div>
  
  </div>
    </div>
    </header>
  );
};

export default Navbar;