import { useState, useEffect, useContext } from "react";
import { collection, addDoc, deleteDoc, doc, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "../../firebase";
import Sidebar from "../sidebar";
import Navbar from "../Navbar";
import { useNavigate } from 'react-router-dom';
import '../admin.css';
import { AuthContext } from '../../context/AuthContext';

const AdminPanel = () => {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [price, setPrice] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [books, setBooks] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [posting, setPosting] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // Check the user's role and navigate to the home page if they are not an admin
    if (!currentUser || currentUser.role !== "admin") {
      navigate('/no-access');
    }
  }, [currentUser, navigate]);

  const handleImageUpload = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);

    // Create a preview for the selected image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  };

  const handleCreateBook = async () => {
    if (!title || !image || !price) {
      alert("Please fill in all required fields.");
      return;
    }

    setPosting(true);

    const imageStorageRef = ref(storage, "book-images/" + image.name);
    await uploadBytes(imageStorageRef, image);

    const imageUrl = await getDownloadURL(imageStorageRef);

    const bookRef = await addDoc(collection(db, "books"), {
      title,
      imageUrl,
      price,
    });

    setBooks([...books, { id: bookRef.id, title, price, imageUrl }]);

    setTitle("");
    setPrice("");
    setImage(null);
    setImagePreview(null);
    setPosting(false);
  };

  const handleDeleteBook = async (bookId) => {
    await deleteDoc(doc(db, "books", bookId));

    const updatedBooks = books.filter((book) => book.id !== bookId);
    setBooks(updatedBooks);

    const book = books.find((book) => book.id === bookId);
    if (book) {
      const imageStorageRef = ref(storage, "book-images/" + book.imageUrl.split("/").pop());
      await deleteObject(imageStorageRef);
    }
  };

  const fetchBooks = async () => {
    const booksCollection = collection(db, "books");
    const querySnapshot = await getDocs(booksCollection);
    const bookList = [];
    querySnapshot.forEach((doc) => {
      bookList.push({ id: doc.id, ...doc.data() });
    });
    setBooks(bookList);
  };

  useEffect(() => {
    fetchBooks();
  }, []);

  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    };
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);

  return (
    <div className="d-flex">
      <div className={toggle ? "d-none" : "w-auto position-fixed"}>
        <Sidebar />
      </div>

      <div className={toggle ? "d-none" : "invisible"}>
        <Sidebar />
      </div>

      <div className='col'>
        <Navbar Toggle={Toggle} />
        <div className="admin">
          <h2>Books Panel</h2>
          <input
            type="text"
            placeholder="Book Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={handleImageUpload}
            placeholder="Your Image"
          />
          <input 
            type="text"
            placeholder="free or enter amount of book with the currency..." 
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Selected"
              style={{ maxWidth: "100%", marginTop: "10px" }}
            />
          )}
          <button onClick={handleCreateBook}>
            {posting ? "Booking..." : "Book Post"}
          </button>

          <ul>
            {books.map((book) => (
              <li key={book.id}>
                <h3>{book.title}</h3>
                <img src={book.imageUrl} alt={book.title} />
                <button onClick={() => handleDeleteBook(book.id)}>Delete</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
