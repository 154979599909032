import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Helmet } from 'react-helmet'; // Import Helmet
import '../../../../Components/Sidebar/sidebar.css';
import Sidebar from '../../../../Components/Sidebar/sidebar';
import Navbar from '../../../../Components/Header/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import Breadcrumbs from "./Breadcrumbs";
import Recent from "../../../../Components/sidebarRecent/Recent";

const NewsDetails = () => {
  const [newsPost, setNewsPost] = useState(null);
  const { id } = useParams();
  const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, [auth]);

  const Toggle = () => setToggle(!toggle);

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    };
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "newsPosts"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.id === id) {
          setNewsPost({ ...doc.data(), id: doc.id });
        }
      });
    };

    fetchData();
  }, [id]);

  if (!newsPost) {
    return <div>Loading...</div>;
  }

  return (
    <div className="d-flex">
      {/* Helmet for dynamic meta tags */}
      <Helmet>
        <meta charSet="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{newsPost.title || "Default News Title"}</title>
        <meta name="description" content={newsPost.content || "Default News Description"} />
        
        {/* Open Graph Meta Tags for Facebook */}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={newsPost.title || "Default News Title"} />
        <meta property="og:description" content={newsPost.content || "Default News Description"} />
        <meta property="og:image" content={newsPost.imageUrl || "default-image-url"} />
        
        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={newsPost.title || "Default News Title"} />
        <meta name="twitter:description" content={newsPost.content || "Default News Description"} />
        <meta name="twitter:image" content={newsPost.imageUrl || "default-image-url"} />
      </Helmet>

      <div className={toggle ? "d-none" : "w-auto position-fixed"}>
        {userIsLoggedIn && <Sidebar />}
      </div>

      <div className={toggle ? "d-none" : "invisible"}>
        {userIsLoggedIn && <Sidebar />}
      </div>

      <div className="col">
        <Navbar Toggle={Toggle} />
        <Breadcrumbs />
        <section id="news-details" className="news-details">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-8">
                <article className="article" key={newsPost.id}>
                  <div className="post-img">
                    <img src={newsPost.imageUrl} alt="" className="img-fluid" />
                  </div>
                  <h2 className="title">{newsPost.title}</h2>
                  <div
                    className="content blog-content"
                    dangerouslySetInnerHTML={{ __html: newsPost.content }}
                  />
                </article>
              </div>
              <Recent />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default NewsDetails;
