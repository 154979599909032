

const Information = () => {
  return (
    <div>
    <section id="PrivacyPolicy">
    <div className="container">
       
       <p>
       All mentoring activities are monitored and recorded for quality and safety purpose. 
        </p>
          <br />
          <p>
          Mentors are required must maintain a zero-tolerance policy for any form of abuse, including but not limited to verbal, physical and emotional abuse. Any behaviour that is abusive or threatening will not be tolerated and will result in immediate termination of the mentoring relationship.
          </p>
          <p>
          Mentors agree to be responsive to mentees once they book an appointment. 
          </p>
          <p>
          Mentors must respect mentees time by being punctual, showing commitment. 
          </p>
          <p>
          Mentees must also respect the mentors and keep the session ‘on topic’.
          </p>
    </div>
   </section>
    </div>
  )
}

export default Information
