import Word from './Word'
import DownloadFIle from './DownloadFile';
import Breadcrubms from './Breadcrumbs';
import './daytopiaInvestmenmt.css';
import Footer from '../../../Components/Footer/Footer';
import { useState, useEffect } from 'react';
import '../../../Components/Sidebar/sidebar.css';
import Navbar from '../../../Components/Header/Navbar';
import '../../../Components/Sidebar/sidebar.css'
import Sidebar from '../../../Components/Sidebar/sidebar';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const DaytopiaInvestmentClub = () => {
    const [toggle, setToggle] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    // Use onAuthStateChanged to listen for changes in user authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserIsLoggedIn(true); // User is logged in
      } else {
        setUserIsLoggedIn(false); // User is not logged in
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]);


  function Toggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    const handleSize = () => {
    if(window.innerWidth > 768) {
      setToggle(false);
    }
}
    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  
  }, []) 


  return (
     <div className="d-flex">
    <div className={toggle ? "d-none" : "w-auto position-fixed"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className= {toggle ? "d-none" : "invisible"} >
    {userIsLoggedIn && <Sidebar />} 
    </div>

    <div className='col'>
    <Navbar Toggle={Toggle}/>
      <Breadcrubms />
     <Word />
     <DownloadFIle />
     <Footer />
     </div>
    </div>
  )
}

export default DaytopiaInvestmentClub