import Header from '../../../Components/Header/Navbar';
import "../Journals.css";
import Changesomeonelife from "../../../assets/image/change-someones-life.webp";
import Gracefinch from "../../../assets/image/grace-finch.webp";
import Fintechgmnews from "../../../assets/image/fintech-gm-news.webp";
import Newgmfeaatured from "../../../assets/image/New-GM-Website-Featured-Images-1.webp";
import Footer from "../../../Components/Footer/Footer";

const CarrerAdvice = () => {
  return (
    <div>
    <Header />
    <section id="blog" className="blog">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
         <h1 className="blog-heading">Carrer Advice</h1>
        <div className="row gy-4 posts-list">
          <div className="col-xl-4 col-md-6">
            <div className="post-item position-relative h-100">
              <div className="post-img position-relative overflow-hidden">
                <img
                  src={Changesomeonelife}
                  className="img-fluid"
                  alt="News Image"
                />
                <span className="post-date">CAREERS ADVICE</span>
              </div>

              <div className="blog-logo">
                <h3>DP</h3>
              </div>

              <div className="post-content d-flex flex-column">
                <h3 className="post-title">
                  Change Someones Life This Summer
                </h3>

                <p>
                  Remember how it felt to start your first job? Exciting and
                  terrifying at the same time perhaps? After coming through
                  the pandemic, the next generation
                </p>

                <hr />
              </div>
            </div>
          </div>
          {/* End post list item */}

          <div className="col-xl-4 col-md-6">
            <div className="post-item position-relative h-100">
              <div className="post-img position-relative overflow-hidden">
                <img src={Newgmfeaatured} className="img-fluid" alt="" />
                <span className="post-date">CAREERS ADVICE</span>
              </div>

              <div className="blog-logo">
                <h3>DP</h3>
              </div>

              <div className="post-content d-flex flex-column">
                <h3 className="post-title">
                  The Life of a Trainee Solicitor
                </h3>

                <p>
                  Graduate Mentor supports change within the legal industry,
                  with more firms offering inclusive opportunities for
                  professional development within the field. Find out first
                  hand from
                </p>

                <hr />
              </div>
            </div>
          </div>
          {/* End post list item */}

          <div className="col-xl-4 col-md-6">
            <div className="post-item position-relative h-100">
              <div className="post-img position-relative overflow-hidden">
                <img src={Gracefinch} className="img-fluid" alt="" />
                <span className="post-date">CAREERS ADVICE</span>
              </div>

              <div className="blog-logo">
                <h3>DP</h3>
              </div>

              <div className="post-content d-flex flex-column">
                <h3 className="post-title">
                  This Fintech Scholarship will Change the Game
                </h3>

                <p>
                  A new survey by fintech business Quotezone found that only
                  50% of students are going to university after secondary
                  school, with 29% heading straight to
                </p>

                <hr />
              </div>
            </div>
          </div>
          {/* End post list item */}
          <div className="col-xl-4 col-md-6">
            <div className="post-item position-relative h-100">
              <div className="post-img position-relative overflow-hidden">
                <img src={Fintechgmnews} className="img-fluid" alt="" />
                <span className="post-date">CAREERS ADVICE</span>
              </div>

              <div className="blog-logo">
                <h3>DP</h3>
              </div>

              <div className="post-content d-flex flex-column">
                <h3 className="post-title">
                  Financial Services Working with the LGBTQ+ Community
                </h3>

                <p>
                  Graduate Mentor are delighted to showcase how things are
                  changing in financial services as demonstrated by Canada
                  Life and how they are embracing diversity as
                </p>

                <hr />
              </div>
            </div>
          </div>
          {/* End post list item */}
        </div>{" "}
        {/*End blog posts list   */}
        <div className="blog-pagination">
          <ul className="justify-content-center">
            <li>
              <a href="#">1</a>
            </li>
            <li className="active">
              <a href="#">2</a>
            </li>
            <li>
              <a href="#">3</a>
            </li>
          </ul>
        </div>
        {/* End blog pagination */}
      </div>
    </section>

    <Footer />
    </div>
  )
}

export default CarrerAdvice
