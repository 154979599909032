/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import elizabeth from '../../../assets/image/about-7.webp';
import GiwaRidwan from '../../../assets/image/Giwa Ridwan.jpg';
import AdejumokeAwonsa from '../../../assets/image/Dr. Adejumoke Awosanya.jpg';
import Sumbobi from '../../../assets/image/Mr. Subomi David.jpeg';
import Temmari from '../../../assets/image/Miss Temlari Janet Gapsiso.jpg';
import Fathimoh from '../../../assets/image/Miss Fatimah Bamisedun.jpeg';
import DeborahBraid from '../../../assets/image/Deborah Braide .jpeg';
import Adetayo  from '../../../assets/image/Adetayo.jpeg';
import Blessing  from '../../../assets/image/Blessing.jpeg';
import Miariam  from '../../../assets/image/Miariam.jpeg';
import Afolabi  from '../../../assets/image/Afolabi.jpeg';
import Oluwafemi from '../../../assets/image/Oluwafemi.jpeg';
import Sarah from '../../../assets/image/sarah.jpeg';
import Babatunde from '../../../assets/image/Babatunde.jpeg';
import Philomina from '../../../assets/image/Philomina.jpeg';
import Musa from '../../../assets/image/Musa.jpeg';
import Lukman from '../../../assets/image/Lukman.jpeg';
import Akay from '../../../assets/image/Akay.jpeg';
import Abdulkhalid from '../../../assets/image/Abdulkhalid.jpeg';
import Kolawole from '../../../assets/image/Kolawole.jpeg';
import Solomon from '../../../assets/image/solomon.jpeg';
import Habillihua from '../../../assets/image/Habibullah Abdulrazak.jpg';
import { Link } from 'react-router-dom';

const TeamCarousel = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 968);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };
  
  return (
    <div>
    <section id="testimonials" className="testimonials section-bg">
    <div className="container">
      <div className="section-title" data-aos="fade-up">
        <h2>Meet the Daytopia Foundation Trustees</h2>
      </div>
     
      <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
        <div className="swiper-wrapper">
       
        <Slider {...settings}>
          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ GiwaRidwan } className="testimonial-img" alt="" />
                <h3>Giwa Ridwan</h3>
                <h4>Chartered Accountant</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Ridwan Giwa is an accounting and finance professional with 7 years experience that spans across the oil and gas servicing and financial services industry.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                   <Link to='/about/daytopiafoundation/Giwa Ridwan'>Read More...</Link>
                </p>
                <a href='https://www.linkedin.com/in/ridwan-giwa-aca-dip-ifr-acca-fmva%C2%AE-prince2-62839a8b/ ' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ AdejumokeAwonsa } className="testimonial-img" alt="Dr. Adejumoke Awosanya" />
                <h3>Dr. Adejumoke Awosanya (Co-Founder)</h3>
                <h4>Co-Founder of Daytopia</h4>
                <a href='https://www.linkedin.com/in/habibullahiabdulrazak/' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

        
          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Akay } className="testimonial-img" alt="Akay Obeka" />
                <h3>Akay Obekpa</h3>
                <h4>Banking, Education and Technology </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Proposed constitution of the Organisation.  Akay Obekpa is an IT professional who has gained over 5 years experience in Banking, Education and Technology sectors in the UK. Over the past years, his role as a student mentor 
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Akay Obekpa'>Read More</Link>
                </p>
                <a href='https://www.linkedin.com/in/akogwu-obekpa-a157ba41/' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Sumbobi } className="testimonial-img" alt="Subomi David" />
                <h3>Subomi David</h3>
                <h4>Subomi David and Data Analyst</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Subomi is an experienced support engineer with wide range experience in data analytics, network security, Project management, implementation, Azure identity support. 
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Subomi David'>Read More</Link>
                </p>
                <a href='https://www.linkedin.com/in/subbydav/' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
          <div className="testimonial-wrap">
            <div className="testimonial-item">
              <img src={ Habillihua } className="testimonial-img" alt="Habillihua" />
              <h3>Habillihua</h3>
              <a href='https://www.linkedin.com/in/habibullahiabdulrazak/' target='_blank'><i className="bi bi-linkedin"></i></a>
            </div>
          </div>
        </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ DeborahBraid } className="testimonial-img" alt="Deborah Braide" />
                <h3>Deborah Braide</h3>
                <h4>Energy and International development specialist</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Deborah Braide is an Energy and International development specialist, World Economic Forum Global Shaper, British Chevening alumni and founder of Enercate, a network for STEM and Sustainable energy capacity development.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Deborah Braide'>Read More...</Link>
                </p>
                <a href='https://www.linkedin.com/in/debbiebraide/' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Fathimoh } className="testimonial-img" alt="Fatimah Bamidesun" />
                <h3>Fatimah Bamisedun</h3>
                <h4>Vice Curator of the World Economic Forum</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Fatimah Bamisedun is an ecosystem catalyst and strategic utility player. Fatimah has garnered over 7+ years of vast experience in corporate and operations strategy, organisation development and IT enablement.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Fatimah Bamisedun'>Read More...</Link>
                </p>
                <a href='https://www.linkedin.com/in/fatimah-bamisedun/' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Kolawole } className="testimonial-img" alt="Mr. Kolawole Seyi" />
                <h3>Mr. Kolawole Seyi</h3>
                <h4>Research Analyst at NASD PLC</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Seyi Kolawole served as a Research Analyst at NASD PLC. He was in charge of market development and facilitating funding opportunities for enterprises across various sectors in Nigeria
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Kolawole Seyi'>Read More...</Link>
                </p>
                <a href='https://www.linkedin.com/in/seyi-kolawole-64610971/' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Sarah } className="testimonial-img" alt="Sarah Egbo" />
                <h3>Sarah Egbo</h3>
                <h4>Information Resources Management </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Sarah Obaro Egbo is a passionate advocate for Gender and digital equality/ equity. She graduated with a first class honors degree in information resources management in 2016 from Babcock University, Ogun state. 
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Sarah Egbo'>Read More...</Link>
                </p>
                <a href='https://www.linkedin.com/in/sarah-egbo/' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Temmari } className="testimonial-img" alt="Janet Temalri" />
                <h3>Janet Temlari </h3>
                <h4>Lawyer at B.B. Dan-Habu </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  They are Smart, Intelligent, and Vibrant when it comes to IT solutions. I recommend
                  the company for any IT needs. My experience with the company was superb just like the name, they are 
                  to smart!!!A graduate of Afe Babalola University and a member of the International Association for the Protection of Intellectual Property (AIPPI) Temlari Jane Gapsiso is an Entertainment and Intellectual Property Lawyer at B.B. Dan-Habu a Corporate Law Firm in Abuja.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Janet Temlari'>Read More...</Link>
                </p>
                <a href='https://www.linkedin.com/in/janet-temlari-gapsiso-3740b6133/' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
          
          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ elizabeth } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>Sanusi Oluwatosin</h3>
                <h4>Public Administration(BSc) </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  They are Smart, Intelligent, and Vibrant when it comes to IT solutions. I recommend
                  the company for any IT needs. My experience with the company was superb just like the name, they are 
                  to smart!!!Sanusi Oluwatosin is a First Class & Best Graduating student of Public Administration(BSc) Adeleke University. An associate member of Chattered Institute Of Personnel Management (CIPM).
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Sanusi Oluwatosin'>Read More...</Link>
                </p>
                <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Adetayo } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>ADETAYO ADEBOWALE</h3>
                <h4>Life Coach and Motivational Leader </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Adetayo Adebowale (@Juliusblety) is a life coach, a motivational leader, poet, born teacher and an aspiring project manager. 
                  With over 13 years wealth of experience in teaching, mentoring and school administration combined with an educational background; a Diploma in Banking & Finance
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Mentor Adetayo'>Read More...</Link>
                </p>
                {/* 
                <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a>
                 */}
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Blessing } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>BLESSING OFURE OKORO </h3>
                <h4>Project Management </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Blessing Ofure Okoro is a seasoned professional and manager with over 10 years hands-on experience coordinating a variety of teams & projects across various industries. Adept at executing complex programs with exceptional abilities in strategy development, personnel management, communication, monitoring & evaluation, budgeting, project management, business 
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Mentor Blessing'>Read More...</Link>
                </p>
                {/*  
                <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a>
                */}
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Miariam } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>Miariam Akinlolu</h3>
                <h4>Lecturer in Construction | Head of Research </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Miariam Akinlolu is a Lecturer in Construction and the Head of Research and Knowledge Exchange at the School of Built Environment at London Metropolitan University. She is also a Research Fellow at Nelson Mandela University. Port Elizabeth, South Africa.  She obtained her BTech. in Project Management from Bells University of Technology and a Master of Construction from the Cape Peninsula University of Technology, South Africa. 
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Mentor Mariam'>Read More...</Link>
                </p>

                {/* <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a> */}

              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Afolabi } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>Olatunde Afolabi, PhD, PMP.</h3>
                <h4>Forensic Expert and Senior Program Manager </h4>
                <p>
                Olatunde Afolabi, PhD, PMP., is an accomplished Forensic Expert and Senior Program Manager with a background in investigative research and healthcare. With a Doctorate degree and Post-Doc in Forensic Genetics, and a certification in Project Management, he expertly navigates complex investigations by applying his extensive expertise developed through his work on 500+ 
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Mentor Afolabi'>Read More...</Link>
                </p>
                {/* <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a> */}
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Oluwafemi } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>Sanyaolu Oluwafemi Akintunde</h3>
                <h4>Accountant </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  I am Sanyaolu, Oluwafemi Akintunde. I hold a Bachelor’s degree in Accounting from Bells University of Technology, Ota, Ogun State, Nigeria; a Master’s degree in Accounting from Covenant University, Ota, Ogun State, Nigeria; 
I am also a Motivational Speaker, Freelance Writer, Mentor, Animator, Author and i co-own two businesses. Furthermore 
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Mentor Oluwafemi'>Read More...</Link>
                </p>
                {/* <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a> */}
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Babatunde } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>Oyelami Babatunde Moshood </h3>
                <h4>Sales Consultant | Business Development Manager</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  I'm currently the International Sales Consultant/ Business Development Manager for one of the largest manufacturing companies in Jiangsu Province in China. As an undergraduate, I earned a first class degree in International Economy and Trade at the Liaoning University of Technology and graduated with a distinction in Business Administration 
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Mentor Babatunde'>Read More...</Link>
                </p>
                {/* <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a> */}
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Lukman } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>Lukman Yusuf </h3>
                <h4>Junior Analyst </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Lukman is a Junior Analyst at The World Bank Treasury, where he applies his expertise in sustainable finance, financial risk management, and data analysis to support the institution's global operations and development goals. He has over two years of experience in the finance sector, spanning various roles and domains, such as venture finance, investment banking, pension management, and business development.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Mentor Lukman'>Read More...</Link>
                </p>
                {/* <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a> */}
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Abdulkhalid } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>Abdulkhalid</h3>
                <h4>Fostering Economic Development | Researcher </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Abdulkhalid is deeply passionate about fostering economic development in the global south. With a background in economics, strong analytical skills, and research experience, he is committed to addressing complex economic issues through evidence-based research and effective communication for policymaking. His recent work as a researcher at the Centre for the Study of the Economies of Africa (CSEA) demonstrate his focus on addressing educational inequities. Beyond research, 
                  <Link to='/about/daytopiafoundation/Mentor Abdulkhaid'>Read More...</Link>
                </p>
                {/* <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a> */}
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Solomon } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>Dr. Bolanle Solomon-Adewusi</h3>
                <h4>Writer, Speaker, and Coach  </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Dr. Bolanle Solomon-Adewusi is a writer, speaker, and coach with solid interests in scholarship-related topics, innovation, product management and research. She holds a Ph.D. in Technology Management with a research focus on ICT innovations.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Mentor Solomon'>Read More...</Link>
                </p>
                {/* <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a> */}
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Philomina } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>Philomina</h3>
                <h4> Economics  </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  An Economics graduate with a background in Finance and Operations who has built experience in treasury, research and cross-functional communication within the financial services sector. As a professional, I demonstrate excellent interpersonal skills and the ability to engage with stakeholders at all levels to ensure the delivery of accurate Finance procedures.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Mentor Phillomina'>Read More...</Link>
                </p>
                {/* <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a> */}
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={ Musa } className="testimonial-img" alt="sanusi Oluwatosin" />
                <h3>Musa Alkassim</h3>
                <h4> Business Analyst  </h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Musa Alkassim is a seasoned business analyst, with demonstrated expertise in business strategy, business intelligence, strategic communications, and business development. With a rich professional background, Musa currently serves as a Data Quality Analyst at MagniTT, contributed as a Communication and Community 
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  <Link to='/about/daytopiafoundation/Mentor Musa'>Read More...</Link>
                </p>
                {/* <a href='https://www.linkedin.com/in/oluwatosin-sanusi/' target='_blank'><i className="bi bi-linkedin"></i></a> */}
              </div>
            </div>
          </div>

          </Slider>

        </div>
        <div className="swiper-pagination"></div>
      </div>

    </div>
  </section>
    </div>
  );
}

export default TeamCarousel
