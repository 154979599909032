import React from 'react';
import './prompt.css'; // Import CSS for styling

const PromptAlert = ({ message, onCancel, onSubmit }) => {
  return (
    <div className='custom_general'>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Notification</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={onCancel}></button>
            </div>
            <div className="modal-body">
              <p>{message}</p>
            </div>
            <div className="modal-footer modal-footer-prompt">
              <button type="button" className="btn " onClick={onCancel}>Cancel</button>
              <button type="button" className="btn "><a href='https://docs.google.com/forms/d/e/1FAIpQLSdNHLODr1fKlAHDN21hNXJ7ePwwQv4Z1V3shP9zNtMQS1GtYw/viewform' target='_blank' rel="noreferrer">Submit</a></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptAlert;
