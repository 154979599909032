import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { applyActionCode, checkActionCode } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyBqSFkjNxx0907yim3-uJhyT_MCkDXdd8A",
  authDomain: "daytopia-base.firebaseapp.com",
  projectId: "daytopia-base",
  storageBucket: "daytopia-base.appspot.com",
  messagingSenderId: "512771698100",
  appId: "1:512771698100:web:ec3a39eb0d27b61d2996e1"
};


 // Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const storage = getStorage(app);
export const db = getFirestore(app);
export { applyActionCode, checkActionCode };
