import { useContext, useState } from "react";
import Img from "../../assets/icons/attach.png";
import { AuthContext } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { v4 as uuid } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { chatsCollection, userChatsCollection } from "../../Utils/collection_names_config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Input = () => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);
  const [imgPreview, setImgPreview] = useState(null); // Added state for image preview

  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      // Check if the file type is an image
      if (!file.type.startsWith('image/')) {
        // Show toast notification for unsupported file type
        toast.error('Only image files are allowed');
        return;
      }
  
      setImg(file);
  
      // Create a preview for the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSend = async () => {
    if (!text && !img) {
      toast.error('Please enter text or select an image to send');
      return;
    }
  
    console.log("Chat ID:", data.chatId);
  
    if (img) {
      const storageRef = ref(storage, uuid());
      const uploadTask = uploadBytesResumable(storageRef, img);
  
      uploadTask.on(
        (error) => {
          console.error("Error uploading image:", error);
          toast.error("Error uploading image. Please try again later.");
  
          // Reset the text input even if image upload fails
          setText("");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            await updateDoc(doc(db, chatsCollection, data.chatId), {
              messages: arrayUnion({
                id: uuid(),
                text,
                senderId: currentUser.uid,
                date: Timestamp.now(),
                img: downloadURL,
              }),
            });
  
            // Reset the text input after successfully sending message
            setText("");
          });
        }
      );
    } else {
      await updateDoc(doc(db, chatsCollection, data.chatId), {
        messages: arrayUnion({
          id: uuid(),
          text,
          senderId: currentUser.uid,
          date: Timestamp.now(),
          img: null,
        }),
      });
  
      // Reset the text input after successfully sending message
      setText("");
    }
  
    // Reset the image state after sending message
    setImg(null);
    setImgPreview(null);
  
    // The rest of your code...
  };
  
  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent default behavior of Enter key (new line)
      handleSend(); // Call handleSend function when Enter key is pressed
    }
  };

  return (
    <div className="input">
      <textarea
        type="text"
        placeholder="Type something..."
        onChange={(e) => setText(e.target.value)}
        value={text}
        onKeyDown={handleKeyPress} // Call handleKeyPress function when a key is pressed
      />
      <div className="send">
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
          onChange={handleFileChange}
        />
        <label htmlFor="file">
          {imgPreview ? (
            <img src={imgPreview} alt="Selected" className="img-preview" />
          ) : (
            <img src={Img} alt="" />
          )}
        </label>
        <i onClick={handleSend} className="inputsend bi bi-send-fill"></i>
      </div>
    </div>
  );
};

export default Input;
