import './Home.css';
import Slide1 from '../../assets/image/one-to-one.webp';
import { Link } from 'react-router-dom';

const Hero = () => {
  const carouselItemStyle1 = {
    backgroundImage: `url(${Slide1})`,
  }

  return (
    <section id="hero">
    <div id="heroCarousel" data-bs-interval="2000" className="carousel slide carousel-fade" data-bs-ride="carousel">

      <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

      <div className="carousel-inner" role="listbox">


        <div className="carousel-item hero_background active"  style={carouselItemStyle1}>
          <div className="carousel-container">
            <div className="container">
              <h2 className="animate__animated animate__fadeInDown">Join our network of experts and experienced Daytopia mentors to start learning critical real-life skills</h2>
              <div className='hero-routes'> 
               <Link to="/mentors" className="btn-get-started animate__animated animate__fadeInUp ">Find Mentor</Link>
               <Link to="/auth/signup" className="btn-get-started2 animate__animated animate__fadeInUp ">Join as a mentee</Link>
              </div>
            
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
  )
}

export default Hero
