import React, { useState, useEffect } from "react";
import { db, auth } from "../../firebase";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";

const EditDisplayName = () => {
  const [editedDisplayName, setEditedDisplayName] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;

    if (user) {
      const userDocRef = doc(db, "users", user.uid);

      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          setEditedDisplayName(doc.data().displayName || "");
        }
      });

      return () => unsubscribe();
    }
  }, []);

  const handleSaveChanges = async () => {
    try {
      const user = auth.currentUser;

      if (user) {
        const userDocRef = doc(db, "users", user.uid);

        // Update the user document in Firestore with the edited display name
        await updateDoc(userDocRef, {
          displayName: editedDisplayName,
        });

        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error updating display name:", error);
    }
  };

  return (
    <div className="container">
    <div className="changename">
      <h3>Change Name</h3>
      <div className="edit-profile-form">
        <div className="edit-name">
          <label htmlFor="displayName">Display Name:</label>
          <input
            type="text"
            name="displayName"
            value={editedDisplayName}
            placeholder="change name"
            onChange={(e) => setEditedDisplayName(e.target.value)}
          />
        </div>
        <button onClick={handleSaveChanges}>Save Changes</button>
      </div>
      </div>
    </div>
  );
};

export default EditDisplayName;
